import redirect from '../../utils/redirectToExternalUrl.js';
import React from 'react';
import PropTypes from 'prop-types';
import Presentation from './Presentation';
import { generateConversationEndpoint } from '../../config.js';
import { deserializeInputComponent } from '@adplabs/e-common/ui-input/deserializeInputComponent';
import { deserializeDisplayComponent } from '@adplabs/e-common/ui-display/deserializeDisplayComponent';
import { attemptToRun } from '../../utils/UserInputQueue';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _log from '../../log';
import { startConversation } from '../../actions/conversation';
import EditableMessage from './EditableMessage';
const log = _log('app:presentation-engine');

const convApiURI = generateConversationEndpoint(window.location);

const noop = () => {};

class PresentationEngine extends React.Component {
	/*
	 * When we get the initial state, our first interaction should be
	 * an instance of the interaction pointed to by the 'start' prop
	 * of the conversation.
	 */
	constructor(props) {
		super(props);

		this.id = Date.now();

		this.parseUriAndStartConversation = this.parseUriAndStartConversation.bind(
			this
		);
		this.start = this.start.bind(this);
		this.convAction = this.convAction.bind(this);

		// This should be an exact and pure replica of interaction state from the server
		this.state = {
			// In the event that we refresh, we will re-use these params to call start() function.
			initialConvStartParams: null,
			messages: [],
			input: null,
			errorMessage: null
		};
	}

	start(canonical, eventID, queryParams, cb = noop) {
		const convRequest = {
			eventID: eventID,
			canonical: canonical,
			queryParams
		};

		fetch(convApiURI, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			body: JSON.stringify(convRequest)
		})
			.then(response => {
				if (response.ok) {
					return response.json();
				} else {
					const error = new Error(response.statusText);
					error.response = response;
					throw error;
				}
			})
			.then(convResponse => {
				cb(null, convResponse);
			})
			.catch(err => {
				cb(err);
			});
	}

	convAction(conversation, action, cb = noop) {
		const convRequest = {
			canonical: conversation.canonical,
			conversationID: conversation.conversationID,
			action
		};

		attemptToRun(function _convAction(next) {
			fetch(convApiURI, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				credentials: 'include',
				body: JSON.stringify(convRequest)
			})
				.then(response => {
					if (response.ok) {
						return response.json();
					} else {
						const error = new Error(response.statusText);
						error.response = response;
						throw error;
					}
				})
				.then(convResponse => {
					cb(null, convResponse);
				})
				.then(res => {
					next(null);
				})
				.catch(err => {
					next(err);
					cb(err);
				});
		});
	}

	parseUriAndStartConversation(location) {
		// If no location was provided, use the current one
		location = location || this.props.location;
		const canonical = location.pathname.replace('/conversation/', '');

		const { query } = location;

		const { eventID } = query;

		// We will need to cache these params so we can recall them later
		const initialConvStartParams = {
			canonical,
			eventID,
			query
		};

		this.setState({
			initialConvStartParams
		});

		return this.start(canonical, eventID, query, (err, convResponse) => {
			this.setState({
				conversation: {
					canonical: canonical,
					conversationID: convResponse.conversationID
				},
				errorMessage: err ? err.message : undefined
			});

			if (!err) {
				this.updateConversation(convResponse);
			}
		});
	}

	getChildContext() {
		return {
			dispatch: action => {
				if (action.type === 'START_CONVERSATION') {
					this.props.dispatch(
						startConversation({
							canonical: action.data.canonical,
							eventID: action.data.event && action.data.event.eventID,
							eventParams: action.data || {}
						})
					);
				} else if (action.type === 'LAUNCH_EXTERNAL_URL') {
					const url = action.data.url;
					redirect(url);
				} else {
					return this.convAction(
						this.state.conversation,
						action,
						(err, convResponse) => {
							if (err) {
								this.setState({
									errorMessage: err.message
								});
							} else {
								if (action.type === 'GOTO') {
									// need to delete all messages after the selected inputID
									this.removeMessagesSince(action.data);
								}
								this.updateConversation(convResponse);
							}
						}
					);
				}
			}
		};
	}

	removeMessagesSince = inputID => {
		const messages = [...this.state.messages] || [];

		// remove all messages after the last one that has matching inputID
		for (let index = messages.length - 1; index >= 0; index -= 1) {
			const message = messages.pop();
			if (message.props.inputID === inputID) {
				break;
			}
		}

		this.setState({
			messages
		});
	};

	/*
	 * Event Handlers
	 */

	updateConversation = convResponse => {
		if (convResponse.messages != null && convResponse.messages.length > 0) {
			convResponse.messages.forEach(serializedMessage => {
				const message = deserializeDisplayComponent(serializedMessage);

				this.state.messages.push(
					<EditableMessage
						key={this.state.messages.length}
						inputID={serializedMessage.inputID}
						actor={serializedMessage.actor}
					>
						{message}
					</EditableMessage>
				);
			});
		}

		if (convResponse.input != null) {
			this.setState({ input: deserializeInputComponent(
				Object.assign(convResponse.input, { key: convResponse.input.inputID })
			) });
		} else {
			this.setState({ input: undefined });
		}

		this.setState({ errorMessage: convResponse.errorMessage });
		this.forceUpdate();
	};

	componentDidMount() {
		this.parseUriAndStartConversation();
	}

	render() {
		log(`Render ${  this.id}`);
		const { query } = this.props.location;

		const { messages, input, errorMessage } = this.state;

		const backButtonValue = query.back || '/timeline';

		/**
		 * todo:
		 * refresh property of Presentation component needs to be a function to refresh current conversation
		 */
		return (
			<Presentation
				back={backButtonValue}
				messages={messages}
				input={input}
				errorMessage={errorMessage}
			/>
		);
	}
}

PresentationEngine.propTypes = {
	location: PropTypes.object.isRequired,
	dispatch: PropTypes.func
};

PresentationEngine.childContextTypes = {
	dispatch: PropTypes.func.isRequired
};

const mapStateToProps = state => {
	return {};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ dispatch }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PresentationEngine);
