import cookies from 'browser-cookies';
import get from 'lodash/get';
import _log from '../log';
const log = _log('app:track-user');
import { eIntl } from '@adplabs/e-common/ui-intl';

const countryCodes = [
	'AD',
	'AE',
	'AF',
	'AG',
	'AI',
	'AL',
	'AM',
	'AO',
	'AQ',
	'AR',
	'AS',
	'AT',
	'AU',
	'AW',
	'AX',
	'AZ',
	'BA',
	'BB',
	'BD',
	'BE',
	'BF',
	'BG',
	'BH',
	'BI',
	'BJ',
	'BL',
	'BM',
	'BN',
	'BO',
	'BQ',
	'BR',
	'BS',
	'BT',
	'BV',
	'BW',
	'BY',
	'BZ',
	'CA',
	'CC',
	'CD',
	'CF',
	'CG',
	'CH',
	'CI',
	'CK',
	'CL',
	'CM',
	'CN',
	'CO',
	'CR',
	'CU',
	'CV',
	'CW',
	'CX',
	'CY',
	'CZ',
	'DE',
	'DJ',
	'DK',
	'DM',
	'DO',
	'DZ',
	'EC',
	'EE',
	'EG',
	'EH',
	'ER',
	'ES',
	'ET',
	'FI',
	'FJ',
	'FK',
	'FM',
	'FO',
	'FR',
	'GA',
	'GB',
	'GD',
	'GE',
	'GF',
	'GG',
	'GH',
	'GI',
	'GL',
	'GM',
	'GN',
	'GP',
	'GQ',
	'GR',
	'GS',
	'GT',
	'GU',
	'GW',
	'GY',
	'HK',
	'HM',
	'HN',
	'HR',
	'HT',
	'HU',
	'ID',
	'IE',
	'IL',
	'IM',
	'IN',
	'IO',
	'IQ',
	'IR',
	'IS',
	'IT',
	'JE',
	'JM',
	'JO',
	'JP',
	'KE',
	'KG',
	'KH',
	'KI',
	'KM',
	'KN',
	'KP',
	'KR',
	'KW',
	'KY',
	'KZ',
	'LA',
	'LB',
	'LC',
	'LI',
	'LK',
	'LR',
	'LS',
	'LT',
	'LU',
	'LV',
	'LY',
	'MA',
	'MC',
	'MD',
	'ME',
	'MF',
	'MG',
	'MH',
	'MK',
	'ML',
	'MM',
	'MN',
	'MO',
	'MP',
	'MQ',
	'MR',
	'MS',
	'MT',
	'MU',
	'MV',
	'MW',
	'MX',
	'MY',
	'MZ',
	'NA',
	'NC',
	'NE',
	'NF',
	'NG',
	'NI',
	'NL',
	'NO',
	'NP',
	'NR',
	'NU',
	'NZ',
	'OM',
	'PA',
	'PE',
	'PF',
	'PG',
	'PH',
	'PK',
	'PL',
	'PM',
	'PN',
	'PR',
	'PS',
	'PT',
	'PW',
	'PY',
	'QA',
	'RE',
	'RO',
	'RS',
	'RU',
	'RW',
	'SA',
	'SB',
	'SC',
	'SD',
	'SE',
	'SG',
	'SH',
	'SI',
	'SJ',
	'SK',
	'SL',
	'SM',
	'SN',
	'SO',
	'SR',
	'SS',
	'ST',
	'SV',
	'SX',
	'SY',
	'SZ',
	'TC',
	'TD',
	'TF',
	'TG',
	'TH',
	'TJ',
	'TK',
	'TL',
	'TM',
	'TN',
	'TO',
	'TR',
	'TT',
	'TV',
	'TW',
	'TZ',
	'UA',
	'UG',
	'UM',
	'US',
	'UY',
	'UZ',
	'VA',
	'VC',
	'VE',
	'VG',
	'VI',
	'VN',
	'VU',
	'WF',
	'WS',
	'YE',
	'YT',
	'ZA',
	'ZM',
	'ZW'
];

export function setLastVisited(path, query, search) {
	try {
		const lastVisitedCookie = cookies.get('lastVisited');
		const lastVisited = lastVisitedCookie ? JSON.parse(lastVisitedCookie) : [];

		if (
			!lastVisited.length ||
			(lastVisited.length && lastVisited[lastVisited.length - 1].path !== path)
		) {
			lastVisited.push({ path, query, search });
		}
		cookies.set(
			'lastVisited',
			JSON.stringify(
				lastVisited.length > 2 ? lastVisited.slice(-2) : lastVisited
			)
		);
	} catch (err) {
		log('ERROR', 'setLastVisited: err', err);
		cookies.set('lastVisited', JSON.stringify([{ path, query, search }]));
	}
}

export function getLastVisited(user) {
	const defaultPath = get(user, 'client.preferences.defaultView', 'chat');
	const defaultView = {
		path: `/${defaultPath}`,
		query: {},
		search: '',
		partnercode: null,
		mediacode: null
	};
	try {
		const lastVisitedCookie = cookies.get('lastVisited');
		const lastVisited = lastVisitedCookie
			? JSON.parse(lastVisitedCookie)
			: [defaultView];
		return lastVisited.length
			? lastVisited[lastVisited.length - 1]
			: defaultView;
	} catch (err) {
		log('ERROR', 'getLastVisited: err', err);
		// replace whatever we had with default view
		cookies.set('lastVisited', JSON.stringify([defaultView]));
		return defaultView;
	}
}

export function deleteLastVisited() {
	cookies.erase('lastVisited');
}

export function getLastVisitedBeforeError() {
	try {
		const lastVisitedCookie = cookies.get('lastVisited');
		const lastVisited = lastVisitedCookie ? JSON.parse(lastVisitedCookie) : [];
		// delete the last page from the list as it caused the error
		lastVisited.pop();
		// update lastVisited cookies
		cookies.set('lastVisited', JSON.stringify(lastVisited));
		// return path to last successfully visited view
		if (lastVisited.length) {
			return lastVisited[lastVisited.length - 1];
		} else {
			return null;
		}
	} catch (err) {
		log('ERROR', 'getLastVisitedBeforeError', err);
		cookies.set('lastVisited', JSON.stringify([]));
		return null;
	}
}

export function getRedirectTo(user) {
	const defaultPath = get(user, 'client.preferences.defaultView', 'chat');

	const defaultView = {
		path: `/${defaultPath}`,
		query: {},
		search: '',
		partnercode: null,
		mediacode: null
	};
	try {
		// verify whether we got cookies from universalLinks plugin
		const universalLinkCookie = cookies.get('universalLink');
		const universalLink = universalLinkCookie
			? JSON.parse(universalLinkCookie)
			: null;
		if (universalLink !== null) {
			cookies.erase('universalLink');
			return universalLink;
		}
		// verify whether we have previos visit history
		return getLastVisited(user);
	} catch (err) {
		log('ERROR', 'getRedirectTo', err);
		cookies.erase('universalLink');
		return defaultView;
	}
}

export function setUserCountryCode(countryCode) {
	log('INFO', 'setUserCountryCode', countryCode);
	if (typeof countryCode === 'undefined' || countryCode === null || countryCode === '') {
		return;
	}
	if (countryCodes.indexOf(countryCode.toUpperCase()) < 0) {
		return;
	}
	cookies.set('e-country-code', String(countryCode).toUpperCase(), {
		expires: 1, // one year
		domain: window.location.hostname.replace('e.', '')
	});
	eIntl.setCountryCode(countryCode);
}

export function getUserCountryCode() {
	return cookies.get('e-country-code');
}

export function deleteUserCountryCode() {
	return cookies.erase('e-country-code', {
		domain: window.location.hostname.replace('e.', '')
	});
}
