/* global SafariViewController */
/* eslint-disable no-undef */
import '@babel/polyfill';
import 'whatwg-fetch'; // eslint-disable-line
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import cookies from 'browser-cookies';
import { isMobile } from 'react-device-detect';
import appHistory from './appHistory';
import authHistory from './authHistory';
import { isAndroid, isUsingCordova, isSBSDesktop, isVersionSameOrAfter } from './utils/appInfo';
import { setUserCountryCode } from './utils/trackUser';
import { isDeviceProtected } from './utils/cordovaSecurityPluginAPI';
import * as AuthUtils from './utils/Auth';
import createScriptTag from './utils/createScriptTag';
import paramsToQueryString from './utils/paramsToQueryString';
import routes from './routes/index';
import Root from './containers/Root';
import Auth, { setStatusBarBgToToken } from './containers/Auth';
import { initLocale } from './initLocale';
import DeviceParams from './utils/deviceParams';
import { geoServiceStop, geoServiceStart } from './utils/geolocation_old';
import BuildInfoHelper from './utils/buildInfoHelper';
import queryStringToParams from './utils/queryStringToParams';
import { startSessionValidationPolling } from './actions/user';
import { setChatOAuthParams } from './actions/chat';
import {
	// eslint-disable-line
	PUBLIC_PATH,
	SOCKET_SERVER_ENDPOINT,
	WS_SERVER_ENDPOINT,
	HTTP_SERVER_ENDPOINT,
	CHAT_SERVER_ENDPOINT,
	DESKTOP_LOGIN_ENDPOINT,
	DEVELOPMENT
} from './constants';
import { getEnvironmentName } from './utils/endpoints';

import { Provider } from 'react-redux'; // eslint-disable-line
import configureStore, { getStore } from './store'; // eslint-disable-line
import pushNotificationRouter from './utils/PushNotificationRouter';
import _log from './log'; // eslint-disable-line
import { rollAnalyticsInit } from './lib/rollAnalytics';
import { eIntl } from '@adplabs/e-common/ui-intl';
import { isIOS } from '@adplabs/e-common/common/utils/AppInfo';
// import store from './store';
import { BaseFontSizeContextProvider } from '@adplabs/e-common/common/utils/BaseFontSizeContext';
import * as SecureKeyStore from './utils/cordovaSecureKeyStorePluginAPI';
// just change this comment to force a new build! 1

const intlNamespace = 'mobile:AuthErrorView';
const log = _log('app:main');
// Start axe in development.
// @see https://www.npmjs.com/package/@axe-core/react
if (DEVELOPMENT) {
	const axe = require('@axe-core/react');
	if(typeof axe === 'function') {
		axe(React, ReactDOM, 5000).catch(e => {
			log('ERROR', `axe promise call error: ${e.message}`);
		});
	}
}

// Generate a public path at runtime
// https://github.com/webpack/docs/wiki/Configuration#outputpublicpath
/* eslint-disable no-global-assign, no-native-reassign */
__webpack_public_path__ = PUBLIC_PATH;
/* eslint-enable no-global-assign, no-native-reassign */

function cordovaResume() {
	log('INFO', 'cordovaResume: cordova resume event handler');
	// GeoService needs to be started only for authenticated users
	SecureKeyStore.getKey('startGeoServiceOnCordovaResume')
		.then((showLocationPrompt) => {
			if (showLocationPrompt !== null) {
				geoServiceStart().then(started => {
					if (!started) {
						log('ERROR', 'cordovaResume: Unable to start geo service');
					}
				})
					.catch(e => {
						log('ERROR', `cordovaResume: geoServiceStart promise call error: ${e.message}`);
					});
			}
		})
		.catch(e => {
			log('ERROR', `cordovaResume: SecureKeyStore.getKey promise call error: ${e.message}`);
		});

	BuildInfoHelper.checkBuildInfoChanged().then(buildChanged => {
		if (buildChanged) {
			log(
				'DEBUG',
				'cordovaResume: detected build changes, going to reload the app'
			);
			location.reload();
		}
	})
		.catch(e => {
			log('ERROR', `cordovaResume: BuildInfoHelper.checkBuildInfoChanged promise call error: ${e.message}`);
		});
}

function cordovaPause() {
	window.rollPauseEventTS = (new Date()).getTime();
	geoServiceStop();
}

const appStartedAt = Date.now();

if (isUsingCordova()) {
	log('INFO', `initial start at: ${appStartedAt}`);
	document.addEventListener('deviceready', cordovaReady);
	// include the appropriate cordova.js
	const cordovaScriptElem = document.createElement('script');
	const cordovaScriptElemSRC = [
		window.location.protocol,
		// need an extra slash when we join()
		'',
		window.location.hostname,
		'm',
		isAndroid() ? 'AndroidCordova' : 'iOSCordova',
		'cordova.js'
	].join('/');

	cordovaScriptElem.setAttribute('src', cordovaScriptElemSRC);
	cordovaScriptElem.setAttribute('type', 'text/javascript');
	cordovaScriptElem.setAttribute('async', true);

	document.body.appendChild(cordovaScriptElem);
	// set security policy
	const elem = document.createElement('meta');
	elem.httpEquiv = 'Content-Security-Policy';
	elem.content = [
		'default-src \'self\' gap: data: blob: \'unsafe-eval\' https://*.google.com  https://*.googleapis.com  https://*.gstatic.com  https://*.googleusercontent.com https://chat.adp.ai \'unsafe-inline\';',
		'frame-src \'self\' gap: https://chat.adp.ai https://adp-e-public-images.s3.amazonaws.com https://static.adp.ai https://www.youtube.com https://*.plaid.com; https://*.cloudfront.net;',
		`connect-src https://analytics.google.com https://www.google-analytics.com https://firebase.googleapis.com/ https://firebaseinstallations.googleapis.com/ ${WS_SERVER_ENDPOINT} ${SOCKET_SERVER_ENDPOINT} ${HTTP_SERVER_ENDPOINT} ${CHAT_SERVER_ENDPOINT} https://external.local.adpeai.com https://ws.local.adpeai.com https://*.plaid.com wss://e.local.adpeai.com/ https://*.googleapis.com wss://ws.feature-ireland.local.adpeai.com/ https://ws.feature-ireland.local.adpeai.com/ wss://feature-ireland.local.adpeai.com/; https://*.amazonaws.com;`,
		'style-src \'self\' \'unsafe-inline\' \'unsafe-eval\' https://*.gstatic.com https://*.googleapis.com https://chat.adp.ai https://*.plaid.com;',
		'media-src * \'unsafe-inline\' \'unsafe-eval\';',
		'img-src \'self\' \'unsafe-inline\' \'unsafe-eval\' https://analytics.google.com https://www.google-analytics.com www.googletagmanager.com https://*.gstatic.com https://*.googleapis.com https://adp-e-public-images.s3.amazonaws.com https://static.adp.ai https://chat.adp.ai https://*.plaid.com https://*.cloudfront.net data: content:;',
		'script-src \'self\' \'unsafe-inline\' \'unsafe-eval\' https://analytics.google.com https://www.google-analytics.com https://ssl.google-analytics.com https://www.googletagmanager.com https://*.gstatic.com https://*.googleapis.com https://*.cloudflare.com https://chat.adp.ai https://external.local.adpeai.com https://ws.local.adpeai.com https://*.plaid.com;'
	].join(' ');
	document.head.appendChild(elem);
} else {
	document.addEventListener('DOMContentLoaded', ready);
}

function cordovaReady() {
	log('INFO', `cordovaReady: initial start took ${Date.now() - appStartedAt}`);
	document.addEventListener('resume', cordovaResume, false);
	document.addEventListener('pause', cordovaPause, false);

	BuildInfoHelper.storeBuildInfo().catch(e => {
		log('ERROR', `cordovaReady: BuildInfoHelper.storeBuildInfo error: ${e.message}`);
	});

	try {
		document.addEventListener(
			'backbutton',
			function (e) {
				e.preventDefault();
			},
			false
		);

		if (window.cordova) {
			if (window.plugins) {
				/* eslint-disable no-undef */
				// subscribe for Universal Links events
				if (window.plugins.universalLinks) {
					window.plugins.universalLinks.subscribe(
						'adpeai',
						window.universalLinksHandler
					);
				}
			}

			/* eslint-disable no-undef */
			if (Keyboard) {
				// Explicitly turning Keyboard.hideFormAccessoryBar method to false, ref #15684
				Keyboard.hideFormAccessoryBar(false);
				/**
				 * Set to true to shrink the WebView when the keyboard comes up.
				 * The WebView shrinks instead of the viewport shrinking and the page scrollable.
				 * This applies to apps that position their elements relative to the bottom of the WebView.
				 * This is the default behaviour on Android, and makes a lot of sense when building
				 * apps as opposed to webpages.
				 *
				 */
				// Checking for Keyboard so we can add/remove the navigation
				window.addEventListener('keyboardWillShow', function () {
					if (
						document.body.classList.contains('adp-e-mobile-keyboard-visible')
					) {
						return;
					}
					// Add class to body
					document.body.classList.add('adp-e-mobile-keyboard-visible');
				});
				window.addEventListener('keyboardWillHide', function () {
					// Remove class from body
					document.body.classList.remove('adp-e-mobile-keyboard-visible');
				});
			}
			/* eslint-disable no-undef */
			if (StatusBar) {
				if (
					window.cordova.platformId === 'android' ||
					window.cordova.platformId === 'Android'
				) {
					if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
						StatusBar.backgroundColorByHexString('#081a2b');
					} else {
						StatusBar.backgroundColorByHexString('#FF999999');
					}
				} else {
					if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
						StatusBar.backgroundColorByHexString('#081a2b');
					} else {
						StatusBar.styleDefault();
					}
				}
			}

			disableDarkModeIfAppDoesNotSupportIt();
		}

		ready().catch(e => {
			log('ERROR', `ready promise call error: ${e.message}`);
		});
	} catch (error) {
		log('ERROR', 'cordovaReady:', {
			...error
		});
	}
}

function ready() {
	const { href, search } = window.location;
	// get CountryCode from query string, should be passed by native app
	if (search.length && search.indexOf('countrycode') >= 0) {
		const queryParams = queryStringToParams(href);
		setUserCountryCode(queryParams.countrycode);
	}

	if (!isUsingCordova()) {
		if (search.length > 1) {
			// In Web version we should handle Authorization Code redirection
			// after authentication with Google/Apple.
			// If authorization code is presented in the query string
			// call handleOpenURL and pass query string
			if (
				search.indexOf('?code') >= 0 ||
				search.indexOf('&code') >= 0 ||
				search.indexOf('plaid_event') >= 0 ||
				search.indexOf('chat_oauth_return') >= 0
			) {
				return initLocale()
					.catch(err => {
						log('ERROR', 'ready: initLocale:', err);
					})
					.finally(() => window.handleOpenURL(href));
			}
			// In Web version we should call Universal Links Handler
			// if InviteID is presented in query string
			// and pass it to registration conversation
			if (search.indexOf('inviteID') >= 0 || search.indexOf('referralcode') >= 0) {
				cookies.set(
					'universalLink',
					JSON.stringify({
						path: '/register',
						query: {},
						search
					})
				);
			} else if (search.indexOf('partnercode') >= 0 || search.indexOf('mediacode') >= 0) {
				const { partnercode = null, mediacode = null } = queryStringToParams(search);
				cookies.set(
					'universalLink',
					JSON.stringify({
						path: '/',
						query: {},
						search: search,
						partnercode,
						mediacode
					})
				);
			}
			if (search.indexOf('languagecode') >= 0) {
				const queryParams = queryStringToParams(href);
				cookies.set('e-accept-language', queryParams.languagecode, {
					expires: 365, // one year
					domain: window.location.hostname.replace('e.', '')
				});
			}
		}

		// check if it's a deep link to the timeline
		const eventID = getEventIDIfDeepLinkToTimeline(href);
		if (eventID) {
			log('INFO', 'ready: deep link to the timeline', { eventID });
			pushNotificationRouter.route({ eventID });
		}
	}

	initLocale()
		.catch(err => {
			log('ERROR', 'ready: initLocale:', err);
		})
		.finally(validateUser);
}

window.launchQRCodeAuth = () => {
	window.location.replace(DESKTOP_LOGIN_ENDPOINT);
};

function validateUser() {
	Promise.all([AuthUtils.getUserStatus(), isDeviceProtected()])
		.then(res => {
			log('INFO', 'validateUser:', {
				userStatus: res[0],
				deviceProtected: res[1]
			});

			const { authenticated = false, survey = '' } =  res[0];
			// if user not authenticated
			// render login view for mobile device
			// and QR code login view for desktop browser
			if (!authenticated) {
				if (isUsingCordova()) {
					// clear IdentityProviderAccountNumber
					SecureKeyStore.removeKey('sub').catch(e => {
						log('ERROR', `SecureKeyStore.removeKey: ${e.message}`);
					});
					// start user experience survey if link is defined
					if (survey !== '') {
						AuthUtils.openLinkInNativeBrowser(survey);
					}
					// render login view
					return launchAuth();
				}

				if (isMobile && getEnvironmentName() !== 'PROD') {
					// isMobile but not cordova, we are running in a browser on device
					// mobile emulator mode
					// if not in PROD, we need to allow DEVS && QA to authenticate on the
					// browser
					return launchAuth();
				}

				// if running in desktop browser send user to desktop
				// qr code login page
				return launchQRCodeAuth();
			}

			// user authenticated, but phone is not secured by PIN
			// log user out and redirect to Login view
			if (isUsingCordova() && window.cordova && !res[1]) {
				return AuthUtils.logOut();
			}

			// render main app
			return launchApp();
		})
		.catch(err => {
			log('ERROR', 'validateUser:', err);
			// in case of user validation error we better log user out and show Auth view
			return AuthUtils.logOut();
		});
}

/**
 * handleOpenURL function works as a callback for Cordova plugin and it should be visible
 * in global name space.
 * When cordova-plugin-safariviewcontroller gets redirection from Authentication-Server with fake
 * protocol in the URL ("adpeai://" instead of "https://"), it calls handleOpenURL ()
 * passing redirection link as a parameter.
 * @param url
 */
window.handleOpenURL = function (url) {
	log('INFO', 'handleOpenURL:', url);
	if (!url) {
		return;
	}

	if (window.cordova) {
		// handleOpenURL is triggered by deep links on Android
		// however on mobile platform  it should work
		// ONLY for URL with fake protocol
		if (url.indexOf('adpeai://') === -1) {
			return;
		}
		SafariViewController.hide();
	}

	const queryParams = queryStringToParams(url);

	if (
		queryParams.chat_oauth_return ||
		queryParams.plaid_event
	) {
		const nextAction = setChatOAuthParams(queryParams);
		if (isUsingCordova()) {
			const store = getStore();
			store.dispatch(nextAction);
			return;
		}

		return launchApp({ nextAction });
	}

	if (!queryParams.code) {
		return launchAuth({ errorMsg: eIntl.formatMessage(`${intlNamespace}.msgAuthError`) });
	}

	// if user canceld log on with external identity provider
	// we should redirect to AuthView
	if (queryParams.code === 'user_cancelled_authorize') {
		return launchAuth();
	}

	AuthUtils.exchangeAuthCodeToAccessToken(queryParams.code)
		.then(res => {
			if (!res || !res.access_token) {
				if (res.errorMsg) {
					return launchAuth({ errorMsg: res.errorMsg });
				}
				throw new Error('Unauthenticated user');
			}
			// convert value to string because cordova key store interprets it as yes if its not a string
			const stepup = `${res.stepUp}`;
			return AuthUtils.launchTheApp(queryParams.provider, stepup);
		})
		.catch(err => {
			log('ERROR', 'handleOpenURL: exchangeAuthCodeToAccessToken::', err);
			// return launchApp({ errorMsg: 'Authentication error' });
			return launchApp({
				errorMsg: eIntl.formatMessage(`${intlNamespace}.msgAuthError`)
			});
		});
};

function authBackButton(event){
	event.preventDefault();
	if (authHistory && authHistory.goBack && typeof authHistory.goBack === 'function') {
		// go back when user presses Android back button
		authHistory.goBack();
	}
}

window.launchAuth = ({ errorMsg = '' } = {}) => {
	document.addEventListener('backbutton',authBackButton);

	// redirect to Login view
	if (errorMsg) {
		authHistory.push(`/error?msg=${errorMsg}`);
	} else {
		authHistory.push('/login');
	}

	ReactDOM.render(
		<BaseFontSizeContextProvider>
			<Auth history={authHistory} />
		</BaseFontSizeContextProvider>
		,
		document.getElementById('root')
	);
	// hide Welcome screen
	AuthUtils.hideWelcomeScreen();
	// Hide the address bar on iOS
	setTimeout(function () {
		window.scrollTo(0, 1);
	}, 0);
};

window.launchApp = ({ errorMsg = '', nextAction } = {}) => {
	/**
	 * initiate plugins and assign callbacks
	 */
	/* eslint-disable no-undef */
	if (window.cordova) {
		document.removeEventListener('backbutton',authBackButton);
		document.addEventListener(
			'backbutton',
			(e) => {
				e.preventDefault();
				// go back when user presses Android back button
				if (appHistory && appHistory.goBack && typeof appHistory.goBack === 'function') {
					appHistory.goBack();
				}
			}
		);

		// subscribe for Push Notifications
		if (window.plugins.pushNotification) {
			window.plugins.pushNotification.register(
				pushNotificationTokenHandler,
				pushNotificationErrorHandler,
				{
					badge: 'true',
					sound: 'true',
					alert: 'true',
					ecb: 'eNotificationAPNHandler'
				}
			);
		}
		// See documentation at https://github.com/appfeel/cordova-push-notifications
		if (typeof FCMPlugin !== 'undefined') {
			FCMPlugin.getToken(function (token) {
				if (token) {
					pushNotificationTokenHandler(token);
				}
			});

			FCMPlugin.onTokenRefresh(pushNotificationTokenHandler);

			FCMPlugin.onNotification(function (data) {
				log('DEBUG', 'FCMPlugin.onNotification', { data });
				if (window.eNotificationAPNHandler) {
					if (data.wasTapped === false) {
						// ticket #14960 - "If the app is in foreground, then do not execute the callback"
						// the only way to know if the app is in foreground is to check "data.wasTapped === false"
					} else {
						window.eNotificationAPNHandler(data);
					}
				} else {
					log('ERROR', 'launchApp: FCM did not find notification callback: ', {
						payload: JSON.stringify(data)
					});
				}
			});
		}
		if (window.cordova.plugins.diagnostic) {
			window.cordova.plugins.diagnostic.isLocationAvailable(
				available => {
					log('DEBUG', 'isLocationAvailable:', available);
				},
				error => {
					log('ERROR', 'isLocationAvailable:', error);
				}
			);
		}
	}

	// non-blocking operation
	rollAnalyticsInit();

	const store = configureStore();

	if (errorMsg) {
		log('ERROR', 'launchApp: App started with error', errorMsg);
		appHistory.replace(`/error?msg=${errorMsg}`);
	} else {
		appHistory.replace('/');
	}

	// hide Welcome screen right before rendering application view
	AuthUtils.hideWelcomeScreen();

	// Set transitional statusbar BG color
	setStatusBarBgToToken('--alias-background-primary');

	ReactDOM.render(
		<BaseFontSizeContextProvider>
			<Provider store={store}>
				<div style={{ height: 'inherit' }}>
					<Root history={appHistory} routes={routes(store)} />
				</div>
			</Provider>
		</BaseFontSizeContextProvider>
		,
		document.getElementById('root')
	);

	// initiate SBS Desktop session validation
	if (isSBSDesktop()) {
		store.dispatch(startSessionValidationPolling());
	}

	if (nextAction) {
		store.dispatch(nextAction);
	}

	// Hide the address bar on iOS
	setTimeout(function () {
		window.scrollTo(0, 1);
	}, 0);

	// load PDFjs library from CDN as it is excluded from the bundle
	// in case of error try to load from static assets
	document.body.appendChild(
		createScriptTag(
			'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.8.335/pdf.min.js',
			'static/vendors/pdf.min.js'
		)
	);
	document.body.appendChild(
		createScriptTag(
			'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.8.335/pdf.worker.min.js',
			'static/vendors/pdf.worker.min.js'
		)
	);
};

window.universalLinksHandler = function (eventData) {
	try {
		log('INFO', 'universalLinksHandler - start', eventData);
		let route = eventData.path;
		if (route.startsWith('/m/') || route === '/m') {
			route = route.substring(2);
		}
		route = route === '' ? '/' : route;
		if (route !== '/' || Object.keys(eventData.params).length) {
			// ignore redirection from Authentication Server with authorization code
			// it should be handled by normal flow
			if (eventData.params.code) {
				return;
			}

			// to support app-to-app redirection for PLAID
			if (eventData.params.plaid_event) {
				const nextAction = setChatOAuthParams(eventData.params);
				const store = getStore();
				store.dispatch(nextAction);
				// closing Safari view with plaid HTML page
				SafariViewController.hide();
				log('INFO', 'universalLinksHandler - Plaid', eventData);
				return;
			} else if (eventData.params.oauth_state_id && eventData.path === '/plaid/index.html' && isIOS()) {
				// got oAuth redirect from PLAID
				SafariViewController.hide();
				const nextAction = setChatOAuthParams({
					plaid_event: 'onRedirect',
					received_redirect_uri: eventData.url
				});
				const store = getStore();
				store.dispatch(nextAction);
				log('INFO', 'universalLinksHandler - Plaid - nextAction', nextAction);
				return;
			}

			// check if it's a deep link to the timeline
			const eventID = getEventIDIfDeepLinkToTimeline(eventData.url);
			if (eventID) {
				log('INFO', 'universalLinksHandler: timeline', { eventID });
				pushNotificationRouter.route({ eventID });
				return;
			}

			// capture and store application Entry Source
			const { partnercode = null, mediacode = null } = eventData.params;

			// in case of invitation user should be redirected
			// to the RegistrationView
			if (eventData.params.inviteID) {
				route = '/register';
			}

			cookies.set(
				'universalLink',
				JSON.stringify({
					path: route,
					query: eventData.params,
					search: paramsToQueryString(eventData.params),
					partnercode,
					mediacode
				}),
				{ expires: 30 }
			);

			if (eventData.params.inviteID) {
				appHistory.replace(`/register?inviteID=${eventData.params.inviteID}`);
			}
			if (eventData && eventData.params && eventData.params.languagecode) {
				cookies.set('e-accept-language', eventData.params.languagecode, {
					expires: 365, // one year
					domain: window.location.hostname.replace('e.', '')
				});
			}
		}
	} catch (error) {
		log('INFO', 'universalLinksHandler:', { eventData, error });
	}
};

function pushNotificationTokenHandler(result) {
	// Your iOS push server needs to know the token before it can push to this device
	// here is where you might want to send it the token for later use.
	DeviceParams.setToken(result);
	AuthUtils.SubmitDeviceToken(result)
		.then(data => {
			log('DEBUG', 'pushNotificationTokenHandler: ', {
				result,
				data
			});
		})
		.catch(err => {
			log('ERROR', 'pushNotificationTokenHandler: ', err);
		});
}

function pushNotificationErrorHandler(error) {
	log('ERROR', 'pushNotificationErrorHandler:', error);
}

function eNotificationAPNHandler(notification) {
	try {
		log('DEBUG', 'eNotificationAPNHandler:', {
			payload: JSON.stringify(notification)
		});
		// do not redirect user to Timeline if application is in foreground, #34168
		if (
			typeof notification.foreground === 'undefined' ||
			notification.foreground === 0 ||
			notification.foreground === '0'
		) {
			pushNotificationRouter.route(notification);
		}
	} catch (e) {
		log('ERROR', 'eNotificationAPNHandler: ', e.message);
		throw e;
	}
}
window.eNotificationAPNHandler = eNotificationAPNHandler;


const disableDarkModeIfAppDoesNotSupportIt = () => {
	const iOSDarkModeAllowed = isIOS() && isVersionSameOrAfter('3.2.14');
	const androidDarkModeAllowed = isAndroid() && isVersionSameOrAfter('3.2.24');

	if (!iOSDarkModeAllowed && !androidDarkModeAllowed) {
		document
			.querySelector(':root')
			.classList
			.remove('dm-ready');
	}
};

function getEventIDIfDeepLinkToTimeline(url) {
	try {
		if (url.indexOf('/timeline?') === -1) {
			return '';
		}
		const eventID = new URL(url).searchParams.get('eventID');
		const isValidEventID = eventID?.search('[a-zA-Z0-9]') >= 0 && eventID?.search('[-]') >= 0;
		if (isValidEventID) {
			return eventID;
		}
	} catch (e) {
		log('ERROR', 'getEventIDIfDeepLinkToTimeline', { url, errorMessage: e.message });
	}
	return '';
}
