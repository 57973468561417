import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconPaperplaneSolid } from '@adplabs/e-common/ui-svg';
import ValidationError from '../../components/auth/ValidationError';
import { eIntl } from '@adplabs/e-common/ui-intl';
import {
	codePolicyValidation,
	passwordPolicyValidation,
	passwordConfirmPolicyValidation
} from '../../utils/policyValidation';
import {
	formClasses,
	formContainerClasses,
	inputClasses,
	linkClasses,
	passwordContainerClasses,
	secondaryTitleStyles,
	submitContainerClasses,
	validationErrorClasses
} from './utilClasses';
import { ActionButton } from '@adplabs/e-common/ui-input/components/ActionButton';
import { PasswordToggle } from './PasswordToggle';
import { BaseFontSizeContext } from '@adplabs/e-common/common/utils/BaseFontSizeContext';

const intlNamespace = 'mobile:AuthForgotPwdView';

export default class ForgotPwdView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			code: '',
			pwd: '',
			pwdConfirm: '',
			visiblePwd: false,
			visiblePwdConfirm: false,
			errorMsgCode: '',
			errorMsgPwd: '',
			errorMsgPwdConfirm: ''
		};
		this.onCodeChange = this.onCodeChange.bind(this);
		this.onPwdChange = this.onPwdChange.bind(this);
		this.onPwdValidate = this.onPwdValidate.bind(this);
		this.onPwdConfirmChange = this.onPwdConfirmChange.bind(this);
		this.onPwdConfirmValidate = this.onPwdConfirmValidate.bind(this);
		this.handleSubmitPwd = this.handleSubmitPwd.bind(this);
		this.onSendCodeClick = this.onSendCodeClick.bind(this);
		this.togglePwdVisible = this.togglePwdVisible.bind(this);
		this.togglePwdConfirmVisible = this.togglePwdConfirmVisible.bind(this);
	}

	onCodeChange(code) {
		if (this.props.serverErrorMsg) {
			this.props.resetServerErrorMsg();
		}
		this.setState(prevState => ({
			...prevState,
			errorMsgCode: '',
			code
		}));
	}

	onPwdChange(pwd) {
		this.setState(prevState => ({
			...prevState,
			errorMsgPwd: '',
			errorMsgPwdConfirm: '',
			pwd
		}));
	}

	onPwdValidate(pwd) {
		if (!pwd) {
			return;
		}
		this.setState(prevState => ({
			...prevState,
			pwd,
			errorMsgPwd: passwordPolicyValidation(pwd)
		}));
	}

	onPwdConfirmChange(pwdConfirm) {
		this.setState(prevState => ({
			...prevState,
			errorMsgPwdConfirm: '',
			pwdConfirm
		}));
	}

	onPwdConfirmValidate(pwdConfirm) {
		if (!pwdConfirm) {
			return;
		}
		this.setState(prevState => ({
			...prevState,
			pwdConfirm,
			errorMsgPwdConfirm: passwordConfirmPolicyValidation(
				this.state.pwd,
				pwdConfirm
			)
		}));
	}

	handleSubmitPwd(e) {
		e.preventDefault();
		// if (this.props.serverErrorMsg) {
		// 	this.props.resetServerErrorMsg();
		// }
		const errorMsgCode = codePolicyValidation(this.state.code);
		const errorMsgPwd = passwordPolicyValidation(this.state.pwd);
		const errorMsgPwdConfirm = passwordConfirmPolicyValidation(
			this.state.pwd,
			this.state.pwdConfirm
		);
		if (errorMsgCode || errorMsgPwd || errorMsgPwdConfirm) {
			this.setState(prevState => ({
				...prevState,
				errorMsgCode,
				errorMsgPwd,
				errorMsgPwdConfirm
			}));
		} else {
			this.props.onPwdSubmit(this.state.code, this.state.pwd);
			e.target.reset();
		}
	}

	onSendCodeClick(e) {
		e.preventDefault();
		if (this.props.serverErrorMsg) {
			this.props.resetServerErrorMsg();
		}
		this.setState(prevState => ({
			...prevState,
			code: '',
			errorMsgCode: '',
			errorMsgPwd: '',
			errorMsgPwdConfirm: ''
		}));
		this.props.onSendCodeClick();
	}

	togglePwdVisible(e) {
		e.preventDefault();
		return this.setState(prevState => ({
			...prevState,
			visiblePwd: !prevState.visiblePwd
		}));
	}

	togglePwdConfirmVisible(e) {
		e.preventDefault();
		return this.setState(prevState => ({
			...prevState,
			visiblePwdConfirm: !prevState.visiblePwdConfirm
		}));
	}

	render() {
		const { userName, onSignInClick, serverErrorMsg, loading } = this.props;

		const {
			errorMsgPwdConfirm,
			pwd,
			pwdConfirm,
			visiblePwdConfirm,
			visiblePwd,
			code,
			errorMsgCode,
			errorMsgPwd
		} = this.state;

		const baseFontSize = this.context.fontSize;
		const adjustForLargeBaseText = baseFontSize > 35;

		return (
			<section className='forgot-password'>
				<h2 className={classNames({ 'text-2xs': adjustForLargeBaseText })}>{eIntl.formatMessage(`${intlNamespace}.header`)}</h2>

				<h3 {...secondaryTitleStyles(adjustForLargeBaseText)}>
					{eIntl.formatMessage(`${intlNamespace}.subheader.weHaveSent`)}{' '}
					<span className='font-demi'>{userName}</span>.{' '}
					{eIntl.formatMessage(`${intlNamespace}.subheader.enterIt`)}
				</h3>

				<div className={formContainerClasses(baseFontSize > 28)}>
					<form onSubmit={this.handleSubmitPwd}>
						<label htmlFor='reset-code' className='sr-only'>
							{eIntl.formatMessage(`${intlNamespace}.placeholderCode`)}
						</label>

						<input
							id='reset-code'
							type='text'
							inputMode='decimal'
							className={inputClasses(adjustForLargeBaseText)}
							placeholder={eIntl.formatMessage(
								`${intlNamespace}.placeholderCode`
							)}
							name='code'
							value={code}
							onChange={e => this.onCodeChange(e.target.value)}
						/>

						<ValidationError
							error={serverErrorMsg || errorMsgCode || null}
							cssClass={validationErrorClasses(adjustForLargeBaseText)}
						/>

						<div className={passwordContainerClasses(!!errorMsgPwd, false, adjustForLargeBaseText)}>
							<label htmlFor='newPassword' className='sr-only'>
								{eIntl.formatMessage(`${intlNamespace}.placeholderPassword`)}
							</label>

							<input
								id='newPassword'
								type={visiblePwd ? 'text' : 'password'}
								placeholder={eIntl.formatMessage(
									`${intlNamespace}.placeholderPassword`
								)}
								name='pwd'
								autoComplete='new-password'
								value={pwd}
								className={inputClasses(adjustForLargeBaseText)}
								onChange={e => this.onPwdChange(e.target.value)}
								onBlur={e => this.onPwdValidate(e.target.value)}
							/>

							<PasswordToggle
								show={visiblePwd}
								onToggle={() =>
									this.setState({
										visiblePwd: !visiblePwd
									})
								}
								hidePasswordSrLabel={eIntl.formatMessage(
									`${intlNamespace}.srHidePassword`
								)}
								showPasswordSrLabel={eIntl.formatMessage(
									`${intlNamespace}.srShowPassword`
								)}
								className={classNames({
									'p-0': baseFontSize >= 33 && !adjustForLargeBaseText
								})}
								size={18}
							/>
						</div>

						<ValidationError
							error={errorMsgPwd}
							cssClass={validationErrorClasses(adjustForLargeBaseText)}
						/>

						<div className={passwordContainerClasses(!!errorMsgPwdConfirm, false, adjustForLargeBaseText)}>
							<label htmlFor='confirmPassword' className='sr-only'>
								{eIntl.formatMessage(
									`${intlNamespace}.placeholderPasswordConfirm`
								)}
							</label>
							<input
								id='confirmPassword'
								type={visiblePwdConfirm ? 'text' : 'password'}
								placeholder={eIntl.formatMessage(
									`${intlNamespace}.placeholderPasswordConfirm`
								)}
								name='pwdConfirm'
								autoComplete='new-password'
								className={inputClasses(adjustForLargeBaseText)}
								value={pwdConfirm}
								onChange={e => this.onPwdConfirmChange(e.target.value)}
								onBlur={e => this.onPwdConfirmValidate(e.target.value)}
							/>

							<PasswordToggle
								show={visiblePwdConfirm}
								onToggle={() =>
									this.setState({
										visiblePwdConfirm: !visiblePwdConfirm
									})
								}
								hidePasswordSrLabel={eIntl.formatMessage(
									`${intlNamespace}.srHidePassword`
								)}
								showPasswordSrLabel={eIntl.formatMessage(
									`${intlNamespace}.srShowPassword`
								)}
								className={classNames({
									'p-0': baseFontSize >= 33 && !adjustForLargeBaseText
								})}
								size={18}
							/>
						</div>
						<ValidationError
							error={this.state.errorMsgPwdConfirm}
							cssClass={validationErrorClasses(adjustForLargeBaseText)}
						/>

						<ActionButton
							type='submit'
							primary
							className={submitContainerClasses(loading, false)}
						>
							<span className='sr-only'>
								{eIntl.formatMessage(`${intlNamespace}.submitSrLabel`)}
							</span>
							<IconPaperplaneSolid />
						</ActionButton>
					</form>

					<div>
						<span className={classNames('text-neutral-800', { 'text-4xs': adjustForLargeBaseText })}>
							{eIntl.formatMessage(`${intlNamespace}.labelGoBackTo`)}
						</span>{' '}
						<button
							type='button'
							onClick={onSignInClick}
							className={classNames(linkClasses(adjustForLargeBaseText),'p-0')}
						>
							{eIntl.formatMessage(`${intlNamespace}.labelSignIn`)}
						</button>
					</div>

					<div>
						<span className={classNames('text-neutral-800', { 'text-4xs': adjustForLargeBaseText })}>
							{eIntl.formatMessage(`${intlNamespace}.labelOr`)}
						</span>{' '}
						<button
							type='button'
							className={classNames(linkClasses(adjustForLargeBaseText), 'p-0')}
							onClick={this.onSendCodeClick}
						>
							{eIntl.formatMessage(`${intlNamespace}.labelResendCode`)}
						</button>
					</div>
				</div>
			</section>
		);
	}
}

ForgotPwdView.contextType = BaseFontSizeContext;

ForgotPwdView.propTypes = {
	userName: PropTypes.string,
	onPwdSubmit: PropTypes.func,
	onSendCodeClick: PropTypes.func,
	onSignInClick: PropTypes.func,
	serverErrorMsg: PropTypes.string,
	resetServerErrorMsg: PropTypes.func,
	loading: PropTypes.bool
};

eIntl.addPart({
	name: intlNamespace,
	getMessages: locale => require(`../../i18n/mobile-${locale}`)
});
