import * as React from 'react';
import PropTypes from 'prop-types';
import { isMobileOnly } from 'react-device-detect';
import { Router, Route, IndexRedirect } from 'react-router-v3-for-frontend-only';
import { LoadingBar } from '@adplabs/e-common/ui-components';
import AuthLayout from '../layouts/AuthLayout';
import LoginView from '../views/auth/LoginView';
import SignInView from '../views/auth/SignInView';
import SignUpView from '../views/auth/SignUpView';
import SignUpConfirmView from '../views/auth/SignUpConfirmView';
import ResetPwdView from '../views/auth/ResetPwdView';
import ForgotPwdView from '../views/auth/ForgotPwdView';
import ErrorView from '../views/auth/ErrorView';
import * as AuthUtils from '../utils/Auth';
import * as SecureKeyStore from '../utils/cordovaSecureKeyStorePluginAPI';
import { isAndroid, isUsingCordova } from '../utils/appInfo';
import {
	getLanguageIdentifier,
	supportedLanguages,
	initLocale
} from '../initLocale';

import _log from '../log';
import { eIntl } from '@adplabs/e-common/ui-intl';

const intlNamespace = 'mobile:AuthErrorView';

const log = _log('app:Auth');

class Auth extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			userName: '',
			userPwd: '',
			error: '',
			loading: false,
			resendingCode: false,
			provider: '',
			language: getLanguageIdentifier()
		};

		this.getSelectedProvider = this.getSelectedProvider.bind(this);
		this.renderLoginView = this.renderLoginView.bind(this);
		this.renderSignInView = this.renderSignInView.bind(this);
		this.renderSignUpView = this.renderSignUpView.bind(this);
		this.renderSignUpConfirmView = this.renderSignUpConfirmView.bind(this);
		this.renderResetPwdView = this.renderResetPwdView.bind(this);
		this.renderForgotPwdView = this.renderForgotPwdView.bind(this);
		this.renderErrorView = this.renderErrorView.bind(this);
		this.beforeRenderErrorView = this.beforeRenderErrorView.bind(this);

		this.resetServerErrorMsg = this.resetServerErrorMsg.bind(this);

		this.forgotPwdInit = this.forgotPwdInit.bind(this);
		this.onMoreOptionsClick = this.onMoreOptionsClick.bind(this);
		this.onAllOptionsClick = this.onAllOptionsClick.bind(this);
		this.onSignInSubmit = this.onSignInSubmit.bind(this);
		this.onResetPwdSubmit = this.onResetPwdSubmit.bind(this);
		this.onSignInClick = this.onSignInClick.bind(this);
		this.onSignUpClick = this.onSignUpClick.bind(this);
		this.onSignUpResend = this.onSignUpResend.bind(this);
		this.onSignUpSubmit = this.onSignUpSubmit.bind(this);
		this.onSignUpConfirmSubmit = this.onSignUpConfirmSubmit.bind(this);
		this.onAppleClick = this.onAppleClick.bind(this);
		this.onGoogleClick = this.onGoogleClick.bind(this);
		this.onForgotPwdClick = this.onForgotPwdClick.bind(this);
		this.onForgotPwdSubmit = this.onForgotPwdSubmit.bind(this);
		this.federationLoginCallback = this.federationLoginCallback.bind(this);
		this.onLanguageChange = this.onLanguageChange.bind(this);
	}

	componentDidMount() {
		// Roll BG is always this color,
		// regardless of light/dark mode
		setStatusBarBgIfNeeded('#4117d6');

		// Register "backbutton" event listener on Andorid to redirect to Login View
		if (isUsingCordova() && isAndroid()) {
			document.addEventListener('backbutton', this.onMoreOptionsClick, false);
		}
	}

	componentWillUnmount() {
		if (isUsingCordova() && isAndroid()) {
			document.removeEventListener(
				'backbutton',
				this.onMoreOptionsClick,
				false
			);
		}
	}

	resetServerErrorMsg() {
		this.setState(prevState => ({
			...prevState,
			error: ''
		}));
	}

	forgotPwdInit() {
		this.setState(prevState => ({
			...prevState,
			loading: true,
			error: ''
		}));
		AuthUtils.InitForgotPassword({
			username: this.state.userName,
			state: ''
		})
			.then(data => {
				if (data.code) {
					if (
						data.code === 'LimitExceededException' ||
						data.code === 'EmailValidationException' ||
						data.code === 'NotAuthorizedException'
					) {
						this.props.history.push(`error?msg=${data.message}`);
					} else if (data.code === 'InvalidParameterException') {
						this.props.history.push('confirm');
					} else {
						// ignore all other exceptions
						this.props.history.push('forgot');
					}
				} else {
					this.props.history.push('forgot');
				}
			})
			.catch(err => {
				log('ERROR', 'forgotPwdInit:', err);
				this.props.history.push(`error?msg=${err.message}`);
			})
			.finally(() => {
				this.setState(prevState => ({
					...prevState,
					loading: false,
					error: ''
				}));
			});
	}

	onForgotPwdSubmit(code, userPwd) {
		this.setState(prevState => ({
			...prevState,
			userPwd,
			loading: true
		}));
		AuthUtils.SubmitForgotPassword({
			username: this.state.userName,
			password: userPwd,
			code,
			state: ''
		})
			.then(data => {
				if (data.confirmed) {
					this.setState(prevState => ({
						...prevState,
						userPwd: '',
						error: '',
						loading: false
					}));
					this.props.history.push('signin');
				}
				if (data.message) {
					this.setState(prevState => ({
						...prevState,
						error: data.message,
						loading: false
					}));
				}
			})
			.catch(err => {
				this.setState({
					userName: '',
					userPwd: '',
					loading: false,
					error: ''
				});
				log('ERROR', 'onForgotPwdSubmit:', err);
				this.props.history.push(`error?msg=${err.message}`);
			});
	}

	onSignInSubmit(userPwd) {
		// show Loading Bar
		if (this.state.loading) {
			return;
		}
		this.setState(prevState => ({
			...prevState,
			userPwd,
			loading: true,
			error: ''
		}));
		AuthUtils.getAuthCode({
			username: this.state.userName,
			password: userPwd,
			state: ''
		})
			.then(data => {
				if (data.authCode) {
					return AuthUtils.exchangeAuthCodeToAccessToken(data.authCode);
				}

				this.setState({ loading: false });

				switch (data.code) {
					case 'NEW_PASSWORD_REQUIRED':
						this.props.history.push('reset');
						break;

					case 'PasswordResetRequiredException':
						this.props.history.push('forgot');
						break;

					case 'UserNotConfirmedException':
						this.props.history.push('confirm');
						break;

					case 'NotAuthorizedException':
						this.setState({ error: data.message });
						break;

					default:
						this.props.history.push(`error?msg=${data.message}`);
						break;
				}

				return null;
			})
			.then(res => {
				if (res !== null) {
					// disable loading indicator after exchanging auth code
					this.setState(prevState => ({
						...prevState,
						loading: false
					}));
					if (!res || !res.access_token) {
						this.props.history.push(
							// `error?msg=${res.errorMsg || 'Authentication error'}`
							`error?msg=${res.errorMsg ||
								eIntl.formatMessage(`${intlNamespace}.msgAuthError`)}`
						);
					} else {
						// convert value to string because cordova key store interprets it as yes if its not a string
						const stepup = `${res.stepUp}`;
						return AuthUtils.launchTheApp('cognito', stepup);
					}
				}
			})
			.catch(err => {
				log('ERROR', 'onPwdSubmit:', err);
				this.setState(prevState => ({
					...prevState,
					loading: false,
					error: ''
				}));
				// this.props.history.push(`error?msg=${'Authentication error'}`);
				this.props.history.push(
					`error?msg=${eIntl.formatMessage(`${intlNamespace}.msgAuthError`)}`
				);
			});
	}

	onResetPwdSubmit(userPwd) {
		this.setState(prevState => ({
			...prevState,
			userPwd,
			loading: true,
			error: ''
		}));
		AuthUtils.SubmitResetPassword({
			username: this.state.userName,
			password: userPwd,
			state: ''
		})
			.then(data => {
				if (data.authCode) {
					return AuthUtils.exchangeAuthCodeToAccessToken(data.authCode);
				} else throw new Error(data.message);
			})
			.then(res => {
				if (res && res.access_token) {
					// convert value to string because cordova key store interprets it as yes if its not a string
					const stepup = `${res.stepUp}`;
					return AuthUtils.launchTheApp('cognito', stepup);
				} else {
					// throw new Error(res.errorMsg || 'Authentication error');
					throw new Error(
						res.errorMsg || eIntl.formatMessage(`${intlNamespace}.msgAuthError`)
					);
				}
			})
			.catch(err => {
				log('ERROR', 'onResetPwdSubmit:', err);
				this.props.history.push(`error?msg=${err.message}`);
			})
			.finally(() => {
				this.setState({
					userName: '',
					userPwd: '',
					loading: false,
					error: ''
				});
			});
	}

	onSignUpClick(e) {
		e.preventDefault();
		this.setState({
			userName: '',
			userPwd: '',
			loading: false,
			error: ''
		});
		this.props.history.push('signup');
	}

	onSignUpSubmit(userName, userPwd) {
		this.setState(prevState => ({
			...prevState,
			userName,
			userPwd,
			loading: true,
			error: ''
		}));
		AuthUtils.SubmitSignUp({
			username: userName,
			password: userPwd,
			state: ''
		})
			.then(data => {
				if (data.code && data.code === 'UserNotConfirmedException') {
					return AuthUtils.ResendConfirmationCode({
						username: this.state.userName,
						state: ''
					});
				}
				return data;
			})
			.then(data => {
				if (data.message) {
					this.setState(prevState => ({
						...prevState,
						error: data.message,
						loading: false
					}));
				} else {
					this.setState(prevState => ({
						...prevState,
						loading: false,
						error: ''
					}));
					return this.props.history.push('confirm');
				}
			})
			.catch(err => {
				log('ERROR', 'onSignUpSubmit:', err);
				this.setState({
					userName: '',
					userPwd: '',
					loading: false,
					error: ''
				});
				this.props.history.push(`error?msg=${err.message}`);
			});
	}

	onSignUpConfirmSubmit(code) {
		this.setState(prevState => ({
			...prevState,
			error: '',
			loading: true
		}));
		AuthUtils.SubmitSignUpConfirm({
			username: this.state.userName,
			code,
			state: ''
		})
			.then(data => {
				if (data.confirmed) {
					/**
					 * automatically Sign in user, #31824
					 */
					this.setState(prevState => ({
						...prevState,
						error: '',
						loading: false
					}));
					return this.onSignInSubmit(this.state.userPwd);
				}
				if (data.message) {
					return this.setState(prevState => ({
						...prevState,
						loading: false,
						error: data.message
					}));
				}
			})
			.catch(err => {
				log('ERROR', 'onSignUpConfirmSubmit:', err);
				this.setState({
					userName: '',
					userPwd: '',
					loading: false,
					error: ''
				});
				this.props.history.push(`error?msg=${err.message}`);
			});
	}

	onSignUpResend(e) {
		if (typeof e !== 'undefined') {
			e.preventDefault();
		}
		this.setState(prevState => ({
			...prevState,
			error: '',
			resendingCode: true
		}));
		AuthUtils.ResendConfirmationCode({
			username: this.state.userName,
			state: ''
		})
			.then(data => {
				if (data.sent || data.message) {
					return this.setState(prevState => ({
						...prevState,
						resendingCode: false,
						error: data.message ? data.message : ''
					}));
				}
			})
			.catch(err => {
				this.setState({
					userName: '',
					userPwd: '',
					resendingCode: false,
					error: ''
				});
				this.props.history.push(`error?msg=${err.message}`);
			});
	}

	onSignInClick() {
		this.setState({
			userName: '',
			userPwd: '',
			loading: false,
			error: ''
		});
		this.props.history.push('signin');
	}

	onAppleClick() {
		this.setState(prevState => ({
			...prevState,
			loading: true
		}));
		return AuthUtils.federationLogin('apple', this.federationLoginCallback);
	}

	onGoogleClick() {
		this.setState(prevState => ({
			...prevState,
			loading: true
		}));
		return AuthUtils.federationLogin('google', this.federationLoginCallback);
	}

	federationLoginCallback(authResult) {
		this.setState(prevState => ({
			...prevState,
			loading: false
		}));
	}

	onMoreOptionsClick() {
		this.setState({
			userName: '',
			userPwd: '',
			loading: false,
			error: ''
		});
		this.props.history.push('login');
	}

	onAllOptionsClick() {
		this.setState({
			provider: ''
		});
	}

	onForgotPwdClick(e) {
		e.preventDefault();
		this.forgotPwdInit();
	}

	onLanguageChange(locale) {
		this.setState(prevState => ({
			...prevState,
			loading: true,
			error: ''
		}));
		initLocale(locale)
			.catch(err => {
				log('ERROR', 'initLocale', err);
			})
			.finally(() => {
				this.setState(prevState => ({
					...prevState,
					loading: false,
					language: locale,
					error: ''
				}));
			});
	}

	renderLoginView() {
		return (
			<LoginView
				onAppleClick={this.onAppleClick}
				onGoogleClick={this.onGoogleClick}
				onEmailClick={this.onSignInClick}
				onAllOptionsClick={this.onAllOptionsClick}
				languages={supportedLanguages}
				defaultLanguage={this.state.language}
				onLanguageChange={this.onLanguageChange}
				providerID={this.state.provider}
				loading={this.state.loading}
			/>
		);
	}

	renderSignInView() {
		return (
			<SignInView
				onUserNameChange={userName => this.setState({ userName })}
				onSignUpClick={this.onSignUpClick}
				onForgotPwdClick={this.onForgotPwdClick}
				serverErrorMsg={this.state.error}
				resetServerErrorMsg={this.resetServerErrorMsg}
				onMoreOptionsClick={this.onMoreOptionsClick}
				providerID={this.state.provider}
				handleSubmit={this.onSignInSubmit}
				loading={this.state.loading}
			/>
		);
	}

	renderSignUpView() {
		return (
			<SignUpView
				onSignUpSubmit={this.onSignUpSubmit}
				onSignInClick={this.onSignInClick}
				serverErrorMsg={this.state.error}
				resetServerErrorMsg={this.resetServerErrorMsg}
				loading={this.state.loading}
			/>
		);
	}

	renderResetPwdView() {
		if (!this.state.userName) {
			this.props.history.push('signin');
		}
		return (
			<ResetPwdView
				onSignInClick={this.onSignInClick}
				onPwdSubmit={this.onResetPwdSubmit}
				loading={this.state.loading}
			/>
		);
	}

	renderForgotPwdView() {
		if (!this.state.userName) {
			this.props.history.push('signin');
		}
		return (
			<ForgotPwdView
				userName={this.state.userName}
				onPwdSubmit={this.onForgotPwdSubmit}
				onSendCodeClick={this.forgotPwdInit}
				onSignInClick={this.onSignInClick}
				serverErrorMsg={this.state.error}
				resetServerErrorMsg={this.resetServerErrorMsg}
				loading={this.state.loading}
			/>
		);
	}

	renderSignUpConfirmView() {
		if (!this.state.userName) {
			this.props.history.push('signin');
		}
		return (
			<SignUpConfirmView
				userName={this.state.userName}
				onCodeSubmit={this.onSignUpConfirmSubmit}
				onResendCodeClick={this.onSignUpResend}
				serverErrorMsg={this.state.error}
				resetServerErrorMsg={this.resetServerErrorMsg}
				onSignInClick={this.onSignInClick}
				loading={this.state.loading}
				resendingCode={this.state.resendingCode}
			/>
		);
	}

	renderErrorView() {
		return (
			<ErrorView
				onGoBack={this.onMoreOptionsClick}
			/>
		);
	}

	beforeRenderErrorView(nextSate, replaceState, next) {
		this.setState(prevState => ({
			...prevState,
			userName: '',
			userPwd: '',
			loading: false,
			error: ''
		}));
		next();
	}

	getSelectedProvider(nextSate, replaceState, next) {
		SecureKeyStore.getKey('provider')
			.then(provider => {
				if (provider !== null) {
					this.setState({
						provider: provider
					});
				}
			})
			.catch(err => {
				log('ERROR', 'getSelectedProvider error', err);
			})
			.finally(() => {
				next();
			});
	}

	render() {
		return (
			<main className="adp-e-auth-container">
				<svg
					className="chat-logo-background"
					version="1.2"
					baseProfile="tiny"
					id="Layer_1"
					xmlns="http://www.w3.org/2000/svg"
					x="0px"
					y="0px"
					viewBox="0 0 45 49"
					preserveAspectRatio="xMidYMid meet"
				>
					<path
						id="IconChatSolid"
						fill="#4117d6"
						d="M41.013,34.707c6.984,-9.981 4.556,-23.734 -5.425,-30.719c-9.981,-6.985 -23.734,-4.556 -30.719,5.425c-6.141,8.774 -5.09,20.693 2.491,28.26c2.818,2.988 1.596,5.993 -0,8.013c-0.496,0.642 -0.378,1.565 0.264,2.061c0.275,0.212 0.616,0.32 0.963,0.305c14.48,-0.638 23.582,-3.181 30.494,-11.007c0.691,-0.741 1.932,-2.338 1.932,-2.338"
					/>
				</svg>

				<section className="content-section">
					{this.state.loading ? <LoadingBar /> : null}
					<Router history={this.props.history}>
						<Route path="/" component={AuthLayout}>
							<IndexRedirect to="/login" />
							<Route
								path="login"
								component={this.renderLoginView}
								onEnter={this.getSelectedProvider}
							/>
							<Route path="signin" component={this.renderSignInView} />
							<Route path="signup" component={this.renderSignUpView} />
							<Route path="reset" component={this.renderResetPwdView} />
							<Route path="forgot" component={this.renderForgotPwdView} />
							<Route path="confirm" component={this.renderSignUpConfirmView} />
							<Route
								path="error"
								component={this.renderErrorView}
								onEnter={this.beforeRenderErrorView}
							/>
						</Route>
					</Router>
				</section>
			</main>
		);
	}
}

/* global StatusBar */
function setStatusBarBgIfNeeded(color) {
	if (isIPhoneWithStatusBar() || isAndroidWithStatusBar()) {
		StatusBar.backgroundColorByHexString(color);
	}
}

export function setStatusBarBgToToken(token) {
	const color = getComputedStyle(document.documentElement)
		.getPropertyValue(token)
		.trim();

	if (color) setStatusBarBgIfNeeded(color);
}

export function resetStatusBarBgIfNeeded() {
	setStatusBarBgIfNeeded('#fff');
}

function isIPhoneWithStatusBar() {
	return (
		window.cordova &&
		StatusBar &&
		window.cordova.platformId.toLowerCase() === 'ios' &&
		isMobileOnly
	);
}

function isAndroidWithStatusBar() {
	return (
		window.cordova &&
		StatusBar &&
		window.cordova.platformId.toLowerCase() === 'android' &&
		isMobileOnly
	);
}

Auth.propTypes = {
	history: PropTypes.object.isRequired
};

export default Auth;
