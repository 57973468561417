import React from 'react';
import PropTypes from 'prop-types';
import { IconPaperplaneSolid } from '@adplabs/e-common/ui-svg';
import ValidationError from '../../components/auth/ValidationError';
import classNames from 'classnames';
import { eIntl } from '@adplabs/e-common/ui-intl';
import {
	emailPolicyValidation,
	passwordPolicyValidation,
	passwordConfirmPolicyValidation,
} from '../../utils/policyValidation';
import { ActionButton } from '@adplabs/e-common/ui-input/components/ActionButton';
import {
	inputClasses,
	submitContainerClasses,
	passwordContainerClasses,
	validationErrorClasses,
} from './utilClasses';
import { PasswordToggle } from './PasswordToggle';
import { BaseFontSizeContext } from '@adplabs/e-common/common/utils/BaseFontSizeContext';

const intlNamespace = 'mobile:AuthSignUpView';
/* eslint-disable no-useless-computed-key */
export default class SignUpView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			pwd: '',
			visiblePwd: false,
			visiblePwdConfirm: false,
			pwdConfirm: '',
			errorMsgEmail: '',
			errorMsgPwd: '',
			errorMsgPwdConfirm: '',
		};

		this.handleSignUpFormSubmit = this.handleSignUpFormSubmit.bind(this);
		this.onEmailChange = this.onEmailChange.bind(this);
		this.onEmailValidate = this.onEmailValidate.bind(this);
		this.onPwdChange = this.onPwdChange.bind(this);
		this.onPwdValidate = this.onPwdValidate.bind(this);
		this.onPwdConfirmChange = this.onPwdConfirmChange.bind(this);
		this.onPwdConfirmValidate = this.onPwdConfirmValidate.bind(this);
		this.togglePwdVisible = this.togglePwdVisible.bind(this);
		this.togglePwdConfirmVisible = this.togglePwdConfirmVisible.bind(this);
	}

	onEmailChange(email) {
		if (this.props.serverErrorMsg) {
			this.props.resetServerErrorMsg();
		}
		this.setState((prevState) => ({
			...prevState,
			email,
			errorMsgEmail: '',
		}));
	}

	onEmailValidate(email) {
		if (email) {
			this.setState((prevState) => ({
				...prevState,
				email,
				errorMsgEmail: emailPolicyValidation(email),
			}));
		}
	}

	onPwdChange(pwd) {
		this.setState((prevState) => ({
			...prevState,
			pwd,
			errorMsgPwd: '',
			errorMsgPwdConfirm: '',
		}));
	}

	onPwdValidate(pwd) {
		if (!pwd) {
			return;
		}
		this.setState((prevState) => ({
			...prevState,
			pwd,
			errorMsgPwd: passwordPolicyValidation(pwd),
		}));
	}

	onPwdConfirmChange(pwdConfirm) {
		this.setState((prevState) => ({
			...prevState,
			pwdConfirm,
			errorMsgPwdConfirm: '',
		}));
	}

	onPwdConfirmValidate(pwdConfirm) {
		if (pwdConfirm) {
			return this.setState((prevState) => ({
				...prevState,
				pwdConfirm,
				errorMsgPwdConfirm: passwordConfirmPolicyValidation(
					this.state.pwd,
					pwdConfirm
				),
			}));
		}
	}

	handleSignUpFormSubmit(e) {
		e.preventDefault();
		const errorMsgEmail = emailPolicyValidation(this.state.email);
		const errorMsgPwd = passwordPolicyValidation(this.state.pwd);
		const errorMsgPwdConfirm = passwordConfirmPolicyValidation(
			this.state.pwd,
			this.state.pwdConfirm
		);
		if (errorMsgEmail || errorMsgPwd || errorMsgPwdConfirm) {
			this.setState((prevState) => ({
				...prevState,
				errorMsgEmail,
				errorMsgPwd,
				errorMsgPwdConfirm,
			}));
		} else {
			this.props.onSignUpSubmit(this.state.email, this.state.pwd);
			e.target.reset();
		}
	}

	togglePwdVisible(e) {
		e.preventDefault();
		return this.setState((prevState) => ({
			...prevState,
			visiblePwd: !prevState.visiblePwd,
		}));
	}

	togglePwdConfirmVisible(e) {
		e.preventDefault();
		return this.setState((prevState) => ({
			...prevState,
			visiblePwdConfirm: !prevState.visiblePwdConfirm,
		}));
	}

	render() {
		const baseFontSize = this.context.fontSize;
		const adjustForLargeBaseText = baseFontSize >= 35;

		return (
			<section className="sign-up">
				<h2
					className={classNames({
						'mb-10': !adjustForLargeBaseText,
						'mb-4 text-3xs': adjustForLargeBaseText,
					})}
				>
					{eIntl.formatMessage(`${intlNamespace}.header`)}
				</h2>

				<div
					className={classNames('form-container', {
						'p-2': adjustForLargeBaseText || (baseFontSize > 28 && baseFontSize < 33 && !adjustForLargeBaseText),
						'p-1': baseFontSize >= 33 && !adjustForLargeBaseText,
						'p-5': !adjustForLargeBaseText && baseFontSize <= 28,
					})}
				>
					<form
						className={classNames({
							'mt-2 mb-6': !adjustForLargeBaseText,
							'mt-1 mb-3': adjustForLargeBaseText
						})}
						onSubmit={this.handleSignUpFormSubmit}
					>
						<label htmlFor="emailAddress" className="sr-only">
							{eIntl.formatMessage(`${intlNamespace}.placeholderEmail`)}
						</label>
						<input
							id="emailAddress"
							type="email"
							inputMode="email"
							className={inputClasses(adjustForLargeBaseText)}
							placeholder={eIntl.formatMessage(
								`${intlNamespace}.placeholderEmail`
							)}
							name="email"
							value={this.state.email}
							onChange={(e) => this.onEmailChange(e.target.value)}
							onBlur={(e) => this.onEmailValidate(e.target.value)}
						/>

						<ValidationError
							cssClass={validationErrorClasses(adjustForLargeBaseText)}
							error={
								this.props.serverErrorMsg || this.state.errorMsgEmail || null
							}
						/>

						<div className={passwordContainerClasses(!!this.state.errorMsgPwd, false, adjustForLargeBaseText)}>
							<label htmlFor="password" className="sr-only">
								{eIntl.formatMessage(`${intlNamespace}.placeholderPassword`)}
							</label>
							<input
								id="password"
								type={this.state.visiblePwd ? 'text' : 'password'}
								placeholder={eIntl.formatMessage(
									`${intlNamespace}.placeholderPassword`
								)}
								name="pwd"
								autoComplete="new-password"
								value={this.state.pwd}
								onChange={(e) => this.onPwdChange(e.target.value)}
								onBlur={(e) => this.onPwdValidate(e.target.value)}
								className={inputClasses(adjustForLargeBaseText)}
							/>

							<PasswordToggle
								show={this.state.visiblePwd}
								onToggle={this.togglePwdVisible}
								hidePasswordSrLabel={eIntl.formatMessage(
									`${intlNamespace}.srHidePassword`
								)}
								showPasswordSrLabel={eIntl.formatMessage(
									`${intlNamespace}.srShowPassword`
								)}
								className={classNames({
									'p-0': baseFontSize >= 33 && !adjustForLargeBaseText
								})}
								size={18}
							/>
						</div>

						<ValidationError
							error={this.state.errorMsgPwd}
							cssClass={validationErrorClasses(adjustForLargeBaseText)}
						/>

						<div
							className={classNames('password-container', {
								'mt-6': !adjustForLargeBaseText,
								'mt-3': adjustForLargeBaseText,
								'mb-6': !this.state.errorMsgPwdConfirm && !adjustForLargeBaseText,
								'mb-3': !this.state.errorMsgPwdConfirm && adjustForLargeBaseText,
							})}
						>
							<label htmlFor="confirmPassword" className="sr-only">
								{eIntl.formatMessage(
									`${intlNamespace}.placeholderPasswordConfirm`
								)}
							</label>
							<input
								id="confirmPassword"
								type={this.state.visiblePwdConfirm ? 'text' : 'password'}
								placeholder={eIntl.formatMessage(
									`${intlNamespace}.placeholderPasswordConfirm`
								)}
								name="pwdConfirm"
								autoComplete="new-password"
								value={this.state.pwdConfirm}
								onChange={(e) => this.onPwdConfirmChange(e.target.value)}
								onBlur={(e) => this.onPwdConfirmValidate(e.target.value)}
								className={inputClasses(adjustForLargeBaseText)}
							/>

							<PasswordToggle
								show={this.state.visiblePwdConfirm}
								onToggle={this.togglePwdConfirmVisible}
								hidePasswordSrLabel={eIntl.formatMessage(
									`${intlNamespace}.srHidePassword`
								)}
								showPasswordSrLabel={eIntl.formatMessage(
									`${intlNamespace}.srShowPassword`
								)}
								className={classNames({
									'p-0': baseFontSize >= 33 && !adjustForLargeBaseText
								})}
								size={18}
							/>
						</div>

						<ValidationError
							error={this.state.errorMsgPwdConfirm}
							cssClass={validationErrorClasses(adjustForLargeBaseText)}
						/>

						<ActionButton
							type="submit"
							primary
							className={submitContainerClasses(this.props.loading)}
						>
							<span className="sr-only">
								{eIntl.formatMessage(`${intlNamespace}.submitSrLabel`)}
							</span>
							<IconPaperplaneSolid />
						</ActionButton>
					</form>

					<div className="mb-2">
						<span className={classNames('text-neutral-800', {
							'text-4xs': adjustForLargeBaseText
						})}>
							{eIntl.formatMessage(`${intlNamespace}.labelAccount`)}
						</span>{' '}
						<button
							type="button"
							onClick={this.props.onSignInClick}
							className={classNames('font-medium', 'text-blue-700', 'p-0', {
								'text-4xs': adjustForLargeBaseText
							})}
						>
							{eIntl.formatMessage(`${intlNamespace}.labelSignIn`)}
						</button>
					</div>
				</div>
			</section>
		);
	}
}

SignUpView.contextType = BaseFontSizeContext;

SignUpView.propTypes = {
	onSignUpSubmit: PropTypes.func,
	onSignInClick: PropTypes.func,
	serverErrorMsg: PropTypes.string,
	resetServerErrorMsg: PropTypes.func,
	loading: PropTypes.bool,
};

eIntl.addPart({
	name: intlNamespace,
	getMessages: (locale) => require(`../../i18n/mobile-${locale}`),
});
