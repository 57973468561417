import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import appHistory from '../appHistory';
import Session from '../UserSession';
import { mediaServerURLBuilder } from '../utils/personAvatar';
import {
	Search,
	setSearchQuery,
	submitSearchQuery,
	cleanSearchQuery,
	getSearchHistory,
	addSearchHistory,
	deleteSearchHistory
} from '@adplabs/e-common/ui-search';

import {
	openPersonProfile,
	removeSelectedGroup,
	setSelectedPerson
} from '@adplabs/e-common/ui-people';

const SEARCH_INPUT_EXPIRATION_TIME = 300000;

export class SearchView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			searchHit: {
				id: null,
				details: null
			}
		};
		this.onSearchInputChange = this.onSearchInputChange.bind(this);
		this.onSearchInputClean = this.onSearchInputClean.bind(this);
		this.onSearchResultItemClick = this.onSearchResultItemClick.bind(this);
		this.onSearchHistoryQueryClick = this.onSearchHistoryQueryClick.bind(this);
		this.onSearchHistoryQueryDelete = this.onSearchHistoryQueryDelete.bind(
			this
		);
		this.onSearchHistoryHitClick = this.onSearchHistoryHitClick.bind(this);
		this.onCancelClick = this.onCancelClick.bind(this);
	}

	componentWillUnmount() {
		const { context } = this.props.params;
		const { query } = this.props.search;
		if (query.length > 2) {
			this.props.dispatch(submitSearchQuery(context, query));
		} else {
			this.props.dispatch(setSearchQuery(context, this.props.search.query));
		}
	}

	componentDidMount() {
		const { context } = this.props.params;
		const { timestamp } = this.props.search;
		// clear expired search input
		if (
			timestamp !== null &&
			Date.now() - timestamp > SEARCH_INPUT_EXPIRATION_TIME
		) {
			this.onSearchInputClean();
		}
		this.props.dispatch(getSearchHistory(context));
	}

	onSearchInputChange(query) {
		const { context } = this.props.params;
		if (query.length > 2) {
			this.props.dispatch(submitSearchQuery(context, query));
		} else {
			this.props.dispatch(setSearchQuery(context, query));
		}
	}

	onSearchInputClean() {
		const { context } = this.props.params;
		this.props.dispatch(cleanSearchQuery(context));
	}

	onSearchResultItemClick(id) {
		const user = Session.getUserSync();
		const { context } = this.props.params;
		const { query, result } = this.props.search;

		const hit = result.find(i => i.id === id);

		if (hit) {
			switch (context) {
				case 'chat':
					break;
				case 'timeline':
					Object.defineProperty(hit, 'source', { enumerable: false });
					this.props.dispatch(
						addSearchHistory(context, query, {
							...hit,
							...hit.source
						})
					);
					Object.defineProperty(hit, 'source', { enumerable: true });
					appHistory.push(`/timeline/${id}`);
					break;
				case 'people':
					Object.defineProperty(hit, 'source', { enumerable: false });
					this.props.dispatch(
						addSearchHistory(context, query, {
							...hit,
							...hit.source
						})
					);
					Object.defineProperty(hit, 'source', { enumerable: true });

					this.props.dispatch(removeSelectedGroup());
					this.props.dispatch(
						setSelectedPerson({
							associateID: hit.associateID,
							personID: hit.personID,
							documentID: hit.avatar ? hit.avatar.photoLink : null,
							formattedName: hit.avatar ? hit.avatar.name : null,
							isMyself: hit.personID === user.personID
						})
					);
					this.props.dispatch(openPersonProfile());
					appHistory.push('/people-profile');
					break;
				default:
					break;
			}
		}
	}

	onSearchHistoryQueryClick(query) {
		const { context } = this.props.params;
		this.props.dispatch(submitSearchQuery(context, query));
	}

	onSearchHistoryQueryDelete(query) {
		const { context } = this.props.params;
		this.props.dispatch(deleteSearchHistory(context, query));
	}

	onSearchHistoryHitClick(id) {
		const user = Session.getUserSync();
		const { context } = this.props.params;
		const hit = this.props.search.history.hits.find(i => i.id === id);
		if (hit) {
			if (context === 'timeline') {
				appHistory.push(`/timeline/${id}`);
			} else if (context === 'people') {
				this.props.dispatch(removeSelectedGroup());
				this.props.dispatch(
					setSelectedPerson({
						associateID: hit.associateID,
						personID: hit.personID,
						isMyself: hit.personID === user.personID
					})
				);
				this.props.dispatch(openPersonProfile());
				appHistory.push('/people-profile');
			}
		}
	}

	onCancelClick() {
		const { context } = this.props.params;
		appHistory.push(`/${  context}`);
	}

	render() {
		const { context } = this.props.params;
		const { query, result, history, loading, timestamp } = this.props.search;
		const user = Session.getUserSync();
		// verify timestamp to prevent rendering expired search input value
		const searchInput =
			timestamp !== null &&
			Date.now() - timestamp > SEARCH_INPUT_EXPIRATION_TIME
				? ''
				: query;
		return (
			<Search
				context={context}
				countryCode={user.countryCode}
				searchInputValue={searchInput}
				searchResult={result}
				searchHistory={history}
				onSearchInputChange={this.onSearchInputChange}
				clearSearchInput={this.onSearchInputClean}
				onCancelClick={this.onCancelClick}
				onSearchResultItemClick={this.onSearchResultItemClick}
				onSearchHistoryQueryClick={this.onSearchHistoryQueryClick}
				onSearchHistoryQueryDelete={this.onSearchHistoryQueryDelete}
				onSearchHistoryHitClick={this.onSearchHistoryHitClick}
				mediaServerURLBuilder={mediaServerURLBuilder}
				loading={loading}
			/>
		);
	}
}

SearchView.propTypes = {
	dispatch: PropTypes.func,

	params: PropTypes.object,
	search: PropTypes.shape({
		query: PropTypes.string,
		result: PropTypes.arrayOf(PropTypes.object),
		timestamp: PropTypes.number,
		history: PropTypes.shape({
			queries: PropTypes.arrayOf(PropTypes.string),
			hits: PropTypes.arrayOf(PropTypes.object)
		}),
		hit: PropTypes.object,
		loading: PropTypes.bool
	})
};

const mapStateToProps = ({ search }, ownProps) => {
	const stateData = search[ownProps.params.context];
	return {
		search: {
			...stateData
		}
	};
};

export default connect(mapStateToProps)(SearchView);
