import * as React from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router-v3-for-frontend-only';
import { BaseFontSizeContext } from '@adplabs/e-common/common/utils/BaseFontSizeContext';

class Root extends React.Component {
	get content() {
		return <Router history={this.props.history}>{this.props.routes}</Router>;
	}

	addBaseFontClasses() {
		const baseFontSize = this.context.fontSize;
		const bodyClasses = document.querySelector('body').classList;

		Object.getOwnPropertyNames(dynamicFontBreakpointClasses).forEach(
			(propName) => {
				const { className, number } = dynamicFontBreakpointClasses[propName];
				if (baseFontSize >= number) {
					bodyClasses.add(className);
				} else if (baseFontSize < number && bodyClasses.contains(className)) {
					bodyClasses.remove(className);
				}
			}
		);
	}

	render() {
		this.addBaseFontClasses();

		return this.content;
	}
}

const dynamicFontBreakpointClasses = {
	thirty: {
		className: 'base-font-min-30',
		number: 30,
	},
	eighteen: {
		className: 'base-font-min-18',
		number: 18,
	},
};

Root.contextType = BaseFontSizeContext;

Root.propTypes = {
	history: PropTypes.object.isRequired,
	routes: PropTypes.object.isRequired,
};

export default Root;
