import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { eIntl } from '@adplabs/e-common/ui-intl';
import {
	getRunnableConversations,
	getConversationsAlreadyRun,
	setCanonicalAwaitingToStartConversation
} from '../actions/chat';
import { startConversation } from '../actions/conversation';

const INITIAL_VISIBLE_TAGS = 4;

const intlNamespace = 'mobile:ChatConversationTags';
const formatMessage = key => eIntl.formatMessage(`${intlNamespace}.${key}`);

class ChatConversationTags extends React.Component {
	constructor(props) {
		super(props);

		this.toggleExpandAvailableList = this.toggleExpandAvailableList.bind(this);
		this.toggleExpandRunAgainList = this.toggleExpandRunAgainList.bind(this);
		this.handleStartConversation = this.handleStartConversation.bind(this);

		this.state = {
			expandedRunAgainList: false,
			expandedAvailableList: false
		};
	}

	componentDidMount() {
		const { runnableConversations } = this.props;
		const { conversations } = runnableConversations;

		if (conversations.length > 0) {
			this.props.dispatch(getConversationsAlreadyRun());
		} else {
			this.props.dispatch(getRunnableConversations());
		}
	}

	handleStartConversation(conv) {
		const { id, canonical, props = {}} = conv;

		this.props.dispatch(setCanonicalAwaitingToStartConversation(id));

		this.props.dispatch(
			startConversation({
				canonical,
				eventParams: props
			})
		);
	}

	toggleExpandRunAgainList() {
		this.setState({
			expandedRunAgainList: !this.state.expandedRunAgainList
		});
	}

	toggleExpandAvailableList() {
		this.setState({
			expandedAvailableList: !this.state.expandedAvailableList
		});
	}

	renderTags(conversations, expanded) {
		const { canonicalAwaitingToStart } = this.props.runnableConversations;

		const renderLoaderIndicator = conv => {
			if (conv.id === canonicalAwaitingToStart) {
				return <div className="busy-indicator" />;
			}

			return null;
		};

		return conversations
			.filter(conv => !!conv.label)
			.filter((conv, index) => expanded || index < INITIAL_VISIBLE_TAGS)
			.map(conv => (
				<button
					key={conv.id}
					disabled={conv.id === canonicalAwaitingToStart}
					className={`chat-conversation-tags-tag background-${conv.canonicalColor ||
						'primary-100'}`}
					onClick={() => {
						this.handleStartConversation(conv);
					}}
				>
					{conv.label}
					{renderLoaderIndicator(conv)}
				</button>
			));
	}

	renderToggleButton(conversations, expanded, onToggleExpand) {
		if (conversations.length <= INITIAL_VISIBLE_TAGS) {
			return null;
		}

		const labelShowLess = formatMessage('showLess');
		const labelShowMore = formatMessage('listAll');
		const label = expanded ? labelShowLess : labelShowMore;

		return (
			<button className="btn-toggle-list-all" onClick={onToggleExpand}>
				{label}
			</button>
		);
	}

	renderRunAgainList() {
		const { runnableConversations } = this.props;
		const { conversations } = runnableConversations;
		const { expandedRunAgainList } = this.state;

		const conversationsAlreadyRun = (conversations || []).filter(
			conversationInfo => !!conversationInfo.timeLineDateTime
		);

		if (conversationsAlreadyRun.length === 0) {
			return null;
		}

		const listHeader = formatMessage('labelRunAgain');

		return (
			<section className="chat-conversation-tags-section">
				<div className="chat-conversation-tags-title">{listHeader}</div>
				{this.renderTags(conversationsAlreadyRun, expandedRunAgainList)}
				{this.renderToggleButton(
					conversationsAlreadyRun,
					expandedRunAgainList,
					this.toggleExpandRunAgainList
				)}
			</section>
		);
	}

	renderAvailableList() {
		const { runnableConversations } = this.props;
		const { conversations } = runnableConversations;
		const { expandedAvailableList } = this.state;

		const availableConversations = (conversations || []).filter(
			conversationInfo => !conversationInfo.timeLineDateTime
		);

		if (availableConversations.length === 0) {
			return null;
		}

		const labelCommonConv = formatMessage('labelCommonConv');
		const labelOnlyCommonConv = formatMessage('labelOnlyCommonConv');

		const bothGroupsVisible =
			availableConversations.length < conversations.length;

		/* check if both groups are visible */
		const listHeader = bothGroupsVisible
			? labelCommonConv
			: labelOnlyCommonConv;

		return (
			<section className="chat-conversation-tags-section">
				<div className="chat-conversation-tags-title">{listHeader}</div>
				{this.renderTags(availableConversations, expandedAvailableList)}
				{this.renderToggleButton(
					availableConversations,
					expandedAvailableList,
					this.toggleExpandAvailableList
				)}
			</section>
		);
	}

	render() {
		const { activeConversationCanonical } = this.props;
		if (
			activeConversationCanonical === 'company.setup' ||
			activeConversationCanonical === 'user.register'
		) {
			return null;
		}

		return (
			<div className="chat-conversation-tags-container">
				{this.renderRunAgainList()}
				{this.renderAvailableList()}
			</div>
		);
	}
}

const ConversationsShape = PropTypes.shape({
	id: PropTypes.string,
	canonical: PropTypes.string,
	intent: PropTypes.string,
	domainCode: PropTypes.string,
	label: PropTypes.string,
	timeLineDateTime: PropTypes.object
});

const RunnableConversationsShape = PropTypes.shape({
	conversations: PropTypes.arrayOf(ConversationsShape),
	canonicalAwaitingToStart: PropTypes.string,
	fetching: PropTypes.bool,
	error: PropTypes.bool
});

ChatConversationTags.propTypes = {
	dispatch: PropTypes.func,
	activeConversationCanonical: PropTypes.string,
	runnableConversations: RunnableConversationsShape
};

ChatConversationTags.defaultProps = {
	dispatch: () => null,
	activeConversationCanonical: '',
	runnableConversations: {
		conversations: [],
		canonicalAwaitingToStart: '',
		fetching: false,
		error: false
	}
};

eIntl.addPart({
	name: intlNamespace,
	getMessages: locale => require(`../i18n/mobile-${locale}`)
});

const mapStateToProps = ({ chat }) => ({
	runnableConversations: chat.runnableConversations,
	activeConversationCanonical: chat.activeConversationCanonical
});

export default connect(mapStateToProps)(ChatConversationTags);
