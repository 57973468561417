import { updateTimelineSubjectIDsIfNeeded } from '../utils/timeline';
import { timelineReducer } from '@adplabs/e-common/ui-timeline';

export default (
	state = {
		currentSubjectIDs: []
	},
	action
) => {
	const stateWithUpdatedSubjectIDs = updateTimelineSubjectIDsIfNeeded(
		state,
		action
	);
	if (stateWithUpdatedSubjectIDs) {
		return stateWithUpdatedSubjectIDs;
	}

	const dealingWithMainTimeline = state.currentSubjectIDs.length === 0;
	if (dealingWithMainTimeline) {
		return state;
	}

	// As actions triggered by socket messages GET_TIMELINE_EVENTS_DETAILS &
	// UPDATE_TIMELINE must be dealt in different moments for each one of the
	// timelines (main and filtered)
	if (action.type === 'UPDATE_TIMELINE') {
		const eventsToUpdate = action.events.filter(
			event =>
				event.subject &&
				state.currentSubjectIDs.includes(event.subject.personID)
		);
		const eventsToRemove = action.eventsToRemove.filter(
			event =>
				event.subject &&
				state.currentSubjectIDs.includes(event.subject.personID)
		);
		return timelineReducer(
			state,
			Object.assign({}, action, {
				events: eventsToUpdate,
				eventsToRemove
			})
		);
	}

	if (action.type === 'GET_TIMELINE_EVENTS_DETAILS') {
		if (action.fetching || !action.response) {
			return state;
		}
		const events = action.response.events.filter(
			event =>
				event.subject &&
				state.currentSubjectIDs.includes(event.subject.personID)
		);
		return timelineReducer(
			state,
			Object.assign({}, action, {
				response: Object.assign({}, action.response, {
					events
				})
			})
		);
	}

	return timelineReducer(state, action);
};
