import React from 'react';
import moment from 'moment-timezone';
import { Redirect, Route } from 'react-router-v3-for-frontend-only';
import authorize, { UnauthorizedError } from '../authorize';
import blurActiveElement from '../utils/blurActiveElement';
import {
	getRedirectTo,
	setLastVisited,
	deleteUserCountryCode
} from '../utils/trackUser';
import * as Auth from '../utils/Auth';
import CoreLayout from '../layouts/CoreLayout';
import ErrorView from '../views/ErrorView';

import TimelineView from '../views/TimelineView';
import PersonProfileView from '../views/PersonProfileView';
import PeoplePageView from '../views/PeoplePageView';
import PeopleGroupView from '../views/PeopleGroupView';
import PeopleProfileView from '../views/PeopleProfileView';
import SearchView from '../views/SearchView';
import ChatView from '../views/ChatView';
import ClientProfileView from '../views/ClientProfileView';
import RegistrationView from '../views/RegistrationView';
import StepUpView from '../views/StepUpView';
import PresentationEngine from '../containers/PresentationEngine';
import QRCodeReaderView from '../views/QRCodeReaderView';
import LogOutView from '../views/LogOutView';
import pushNotificationRouter from '../utils/PushNotificationRouter';
import { geoServiceInit } from '../utils/geolocation_old';
import DeviceParams from '../utils/deviceParams';
import { isSBSDesktop } from '../utils/appInfo';
import { initLocale } from '../initLocale';
import * as SecureKeyStore from '../utils/cordovaSecureKeyStorePluginAPI';
import _log from '../log';
import { getSocketConnection } from '../actions/socket';
import { setUserInfo, updateDeviceInfo, submitUserVisit } from '../actions/user';
import {
	removeSelectedGroup,
	removeSelectedPerson
} from '@adplabs/e-common/ui-people';
import { setCUID } from '../lib/rollAnalytics/analytics';

const log = _log('app:router');

const setDocumentTitle = title => {
	document.title = `ADP Roll - ${title}`;
};

const onEnter = ({ documentTitle }) => (nextState, replace, next) => {
	setDocumentTitle(documentTitle);

	log('INFO', 'onEnter: next location', nextState.location);
	blurActiveElement()
		.then(authorize)
		.then(user => {
			if (
				user.personID === null &&
				nextState.location.pathname !== '/register' &&
				nextState.location.pathname !== '/error' &&
				nextState.location.pathname !== '/logout'
			) {
				replace(`/register${  nextState.location.search}`);
			} else {
				// store in session last visited page
				if (
					nextState.location.pathname !== '/register' &&
					nextState.location.pathname !== '/error' &&
					nextState.location.pathname !== '/qr-code-reader' &&
					nextState.location.pathname !== '/logout'
				) {
					setLastVisited(
						nextState.location.pathname,
						nextState.location.query,
						nextState.location.search
					);
				}
			}
			next();
		})
		.catch(err => {
			log('ERROR', 'onEnter', err.message);
			// Only show the error message for actual errors
			if (err instanceof UnauthorizedError) {
				// validate User Authentication status and redirect
				// to either login page or error view
				Auth.isUserAuthenticated()
					.then(res => {
						if (!res) {
							// if user is not authenticated yet we should redirect to login page
							return window.launchAuth();
						} else {
							// simply redirect to error view
							replace(`/error?msg=${  err.message}`);
						}
					})
					.catch(error => {
						replace(`/error?msg=${  error.message}`);
					})
					.finally(() => {
						next();
					});
			} else {
				replace(`/error?msg=${  err.message}`);
				next();
			}
		});
};

const clearPeopleStateAndEnter = ({ store, documentTitle }) => (
	nextState,
	replace,
	next
) => {
	store.dispatch(removeSelectedGroup());
	store.dispatch(removeSelectedPerson());
	onEnter({ documentTitle })(nextState, replace, next);
};

const onInternalPeopleEnter = ({ store, documentTitle }) => (
	nextState,
	replace,
	next
) => {
	const { people } = store.getState();
	if (!people || (!people.selectedPerson && !people.selectedGroup)) {
		replace('/people');
	}
	return onEnter({ documentTitle })(nextState, replace, next);
};

const onLogin = store => (nextState, replace, next) => {
	const { pathname, query } = nextState.location;

	// clean the CoutryCode from cookies
	deleteUserCountryCode();

	// in case of error proceed to the view
	if (pathname === '/error') {
		return next();
	}

	// in case of error message in query string
	// redirect to the error view
	if (query.error) {
		replace(`/error?msg=${query.error}`);
		return next();
	}

	authorize()
		.then(user => {
			store.dispatch(setUserInfo(user));

			// set UserAccountID property in Roll Analytics
			if (user.userAccountID) {
				setCUID(user.userAccountID);
			}
			// this is for GeoIP2 lambda to use user identity next time
			if (user.identityProviderAccountNumber) {
				SecureKeyStore.setKey('sub', user.identityProviderAccountNumber);
			}

			if (pathname !== '/stepup') {
				return new Promise((resolve, reject) => {
					SecureKeyStore.getKey('stepup').then((stepup) => {
						if(stepup && stepup === 'true'){
							replace('/stepup');
							resolve(null);
						} else {
							resolve(user);
						}
					})
						.catch(err => {
							replace(`/error?msg=${err}`);
							resolve(user);
						});
				});
			} else {
				return Promise.resolve(user);
			}
		})
		.then(user => {
			// This if statement is strictly for stepup conversation use, the conditions before the next() allow UI to stop loading spinner and get socket connection
			if (!user || pathname === '/stepup') {
				const state = store.getState();
				if (state.socket && !state.socket.connected) {
					store.dispatch(getSocketConnection());
				}
				return next();
			}
			// if path is not defined check last visited page
			if (pathname === '/') {
				const redirectTo = getRedirectTo(user);
				log('INFO', 'onLogin getRedirectTo', redirectTo);
				if (redirectTo.partnercode || redirectTo.mediacode) {
					Auth.submitApplicationEntrySource({ partnercode: redirectTo.partnercode, mediacode: redirectTo.mediacode }).catch(e => {
						log('ERROR', `submitApplicationEntrySource promise error: ${e.message}`);
					});
				}
				if (redirectTo.path) {
					replace(`${redirectTo.path}${redirectTo.search}`);
				}
			} else {
				const state = store.getState();
				if (state.socket && !state.socket.connected) {
					store.dispatch(getSocketConnection());
				}

				pushNotificationRouter.setStore(store);
				pushNotificationRouter.notifyAppReady();

				geoServiceInit()
					.then(initLocale(user.localeCode))
					.then(started => {
						log('DEBUG', 'onLogin: geoServiceInit', started);
						// set flag for Geo service
						SecureKeyStore.setKey('startGeoServiceOnCordovaResume', 1);

						if (user.personID !== null && !user.actingAsID) {
							// define device parameters for registered user
							return DeviceParams.gatherDeviceParameters();
						} else {
							return Promise.resolve({});
						}
					})
					.then(params => {
						log('DEBUG', 'DeviceParams:', params);
						if (user.personID !== null && !user.actingAsID) {
							if (params.deviceID) {
								store.dispatch(updateDeviceInfo(params));
							}
							if (!isSBSDesktop()) {
								store.dispatch(submitUserVisit({
									timeZoneCode: moment.tz.guess(),
									deviceName: params.deviceName,
									deviceID: params.deviceID
								}));
							}
						}
					})
					.catch(err => {
						log('ERROR', 'DeviceParams: error', err);
					});

				if (pushNotificationRouter.hasNotification()) {
					pushNotificationRouter.route();
				}
			}
			// set language from UserObject for SBS Desktop only
			if (isSBSDesktop()) {
				return initLocale(user.localeCode);
			}
		})
		.catch(err => {
			log('ERROR', err);
			replace(`/error?msg=${  query.error}`);
		})
		.finally(next);
};

function Routes(store) {
	return (
		<Route path="/" component={CoreLayout} onEnter={onLogin(store)}>
			<Route path="error" component={ErrorView} />
			{/* ROUTES REQUIRING AUTHORIZATION */}

			<Route
				path="timeline"
				component={TimelineView}
				onEnter={clearPeopleStateAndEnter({ store, documentTitle: 'Timeline' })}
			>
				<Route path=":eventID" />
			</Route>
			<Route
				path="people"
				component={PeoplePageView}
				onEnter={clearPeopleStateAndEnter({ store, documentTitle: 'People' })}
			/>
			<Route
				path="person"
				component={PersonProfileView}
				onEnter={clearPeopleStateAndEnter({ store, documentTitle: 'Person' })}
			/>
			<Route
				path="people-group"
				component={PeopleGroupView}
				onEnter={onInternalPeopleEnter({
					store,
					documentTitle: 'People Group'
				})}
			/>
			<Route
				path="people-profile"
				component={PeopleProfileView}
				onEnter={onInternalPeopleEnter({
					store,
					documentTitle: 'People Profile'
				})}
			/>
			<Route
				path="chat(/:redirect)"
				component={ChatView}
				onEnter={clearPeopleStateAndEnter({ store, documentTitle: 'Chat' })}
			/>
			<Route
				path="client"
				component={ClientProfileView}
				onEnter={clearPeopleStateAndEnter({ store, documentTitle: 'Client' })}
			/>

			<Route
				path="search/:context"
				component={SearchView}
				onEnter={onEnter({ documentTitle: 'Search' })}
			/>

			{/* CONVERSATION PRESENTATION ENGINE CATCHALL */}
			<Route
				path="conversation/*"
				component={PresentationEngine}
				onEnter={onEnter({ documentTitle: 'Conversation' })}
			/>

			{/* QR CODE SCANNER */}
			<Route
				path="qr-code-reader"
				component={QRCodeReaderView}
				onEnter={onEnter({ documentTitle: 'QR Code Reader' })}
			/>

			{/* USER REGISTRATION CONVERSATION */}
			<Route
				path="register(/:redirect)"
				component={RegistrationView}
				onEnter={onEnter({ documentTitle: 'Register' })}
			/>

			{/* STEPUP CONVERSATION TWO FACTOR */}
			<Route
				path="stepup(/:redirect)"
				component={StepUpView}
				onEnter={onEnter({ documentTitle: 'StepUp Auth' })}
			/>

			{/* APPLICATION SETTINGS, USER PREFERENCES */}
			<Route path="logout" component={LogOutView} />
			{/* LOGIN DEFAULT INSTEAD OF ERROR */}
			<Redirect path="login" to="chat" />
			{/* GENERIC ERROR CATCHALL */}
			<Redirect from="*" to="error" />
		</Route>
	);
}

export default Routes;
