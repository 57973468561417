import socket from './socket';
import { combineReducers } from 'redux';
import { clockReducer } from '@adplabs/e-common/ui-timeline';
import {
	errorManagerReducer,
	helpReminderReducer
} from '@adplabs/e-common/ui-error';
import { peopleReducer } from '@adplabs/e-common/ui-people';
import { searchReducer } from '@adplabs/e-common/ui-search';
import { timeSheetReducer } from '@adplabs/e-common/ui-time';
import liveChat from './liveChat';
import filteredTimeline from './filteredTimeline';
import timeline from './timeline';
import timelineSocket from './timelineSocket';
import user from './user';
import header from './header';
import searchSettings from './searchSettings';
import chat from './chat';
import layout from './layout';
import rightPanel from './rightPanel';
import navigationMenuPopup from './navigationMenuPopup';
import rateAndReview from './rateAndReview';

export default combineReducers({
	chat,
	clock: clockReducer,
	errorManager: errorManagerReducer,
	filteredTimeline,
	header,
	layout,
	liveChat,
	navigationMenuPopup,
	people: peopleReducer,
	search: searchReducer,
	searchSettings,
	socket,
	timeline,
	timelineSocket,
	user,
	rightPanel,
	timeSheet: timeSheetReducer,
	rateAndReview,
	helpReminder: helpReminderReducer
});
