import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PeopleProfileView from './PeopleProfileView';
import TimelineView from './TimelineView';
import GroupProfileView from './GroupProfileView';
import { eIntl } from '@adplabs/e-common/ui-intl';
import {
	PeopleGroupHeader,
	getSelectedPersonDetails,
	removeSelectedPerson,
	setSelectedPerson,
	setSelectedGroup
} from '@adplabs/e-common/ui-people';
import { setHeaderTitleToken } from '../actions/header';
import { setSearchSettings } from '../actions/searchSettings';

import { peopleServicesURL } from '../utils/endpoints';
import {
	setRightPanelComponent,
	clearRightPanelComponent
} from '../actions/rightPanel';
import { SkipToNav } from '../components/SkipToNav';
const peopleAssociateService = `${peopleServicesURL}/v1/associate`;

const intlNamespace = 'mobile:PeopleView';

/**
 * Show a specific group and its members
 * It also shows recent timeline events related to the members or a member profile (people tiles)
 */
class PeopleGroupView extends React.Component {
	constructor(props) {
		super(props);
		this.handleOnGroupClick = this.handleOnGroupClick.bind(this);
		this.handlePersonClick = this.handlePersonClick.bind(this);
	}

	componentDidMount() {
		this.props.dispatch(
			setHeaderTitleToken(`${intlNamespace}.header`)
		);

		this.props.dispatch(
			setSearchSettings({
				hidden: true,
				searchPath: '/search/people'
			})
		);
	}

	handleOnGroupClick() {
		const {
			dispatch,
			selectedGroup: { title, members, groupType },
			selectedPerson,
			isDesktopLayout
		} = this.props;
		if (selectedPerson) {
			dispatch(removeSelectedPerson());
		}
		dispatch(setSelectedGroup(title, members, groupType));

		if (isDesktopLayout) {
			dispatch(clearRightPanelComponent());
		}
	}

	handlePersonClick(person) {
		const { myself } = this.props;
		this.props.dispatch(setSelectedPerson(person));

		if (this.props.isDesktopLayout) {
			this.props.dispatch(
				setRightPanelComponent({
					type: 'PEOPLE_PROFILE'
				})
			);
		} else {
			this.props.dispatch(
				getSelectedPersonDetails(person, myself, peopleAssociateService)
			);
		}
	}

	renderGroupHeader() {
		const { selectedGroup } = this.props;
		return (
			<div className="adp-e-mobile-timeline-groupcard-container">
				<PeopleGroupHeader
					group={selectedGroup}
					onPersonClick={this.handlePersonClick}
					onGroupClick={this.handleOnGroupClick}
					hideSeparationLine={
						this.props.showTeamTiles &&
						selectedGroup.groupType === 'team' &&
						!this.props.selectedPerson
					} // TODO: temporary logic to show Team Tiles only for donuts until we have wsbe implementation
				/>
			</div>
		);
	}

	renderGroupTimelineOrPersonProfile() {
		const { selectedPerson, isDesktopLayout } = this.props;

		if (isDesktopLayout) {
			return <TimelineView />;
		}

		return selectedPerson ? (
			<PeopleProfileView isInsideGroupView />
		) : (
			<TimelineView />
		);
	}

	renderGroupProfileOrPersonProfile() {
		const { selectedPerson } = this.props;
		return selectedPerson ? (
			<PeopleProfileView isInsideGroupView />
		) : (
			<GroupProfileView />
		);
	}

	render() {
		if (!this.props.selectedGroup) {
			return null;
		}

		const groupHeader = this.renderGroupHeader();
		// const groupTimelineOrPersonProfile = this.renderGroupTimelineOrPersonProfile();
		const groupProfileOrPersonProfile =
			this.props.showTeamTiles && this.props.selectedGroup.groupType === 'team'
				? this.renderGroupProfileOrPersonProfile()
				: this.renderGroupTimelineOrPersonProfile(); // TODO: temporary logic to show Team Tiles only for donuts until we have wsbe implementation

		return (
			<section
				className="people-view-container"
				role="main"
				aria-label="People group view"
			>
				<SkipToNav />
				<div className="adp-e-mobile-content">
					{groupHeader}
					{groupProfileOrPersonProfile}
				</div>
			</section>
		);
	}
}

PeopleGroupView.propTypes = {
	myself: PropTypes.object,
	selectedGroup: PropTypes.shape({
		title: PropTypes.string,
		members: PropTypes.arrayOf(
			PropTypes.shape({
				associateID: PropTypes.string,
				personID: PropTypes.string,
				documentID: PropTypes.string,
				givenName: PropTypes.string,
				formattedName: PropTypes.string,
				initials: PropTypes.string,
				positionName: PropTypes.string,
				timezone: PropTypes.string
			})
		),
		groupType: PropTypes.string
	}),
	selectedPerson: PropTypes.shape({
		associateID: PropTypes.string,
		personID: PropTypes.string,
		isMyself: PropTypes.bool
	}),
	showTeamTiles: PropTypes.bool, // TODO: temporary logic to show Team Tiles only for donuts until we have wsbe impementation
	dispatch: PropTypes.func,
	isDesktopLayout: PropTypes.bool
};

const mapStateToProps = ({ people, layout }) => ({
	myself: people.myself,
	selectedGroup: people.selectedGroup,
	selectedPerson: people.selectedPerson,
	showTeamTiles: people.showTeamTiles, // TODO: temporary logic to show Team Tiles only for donuts until we have wsbe implementation
	isDesktopLayout: layout.isDesktopLayout
});

eIntl.addPart({
	name: intlNamespace,
	getMessages: locale => require(`../i18n/mobile-${locale}`)
});

eIntl.addPart({
	name: 'mobile:Connection',
	getMessages: locale => require(`../i18n/mobile-${locale}`)
});

export { PeopleGroupView };
export default connect(mapStateToProps)(PeopleGroupView);
