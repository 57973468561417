import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconPaperplaneSolid } from '@adplabs/e-common/ui-svg';
import ValidationError from '../../components/auth/ValidationError';
import { emailPolicyValidation } from '../../utils/policyValidation';
import { eIntl } from '@adplabs/e-common/ui-intl';
import { ActionButton } from '@adplabs/e-common/ui-input/components/ActionButton';
import {
	formContainerClasses,
	formClasses,
	inputClasses,
	passwordContainerClasses,
	submitContainerClasses,
	linkClasses,
	validationErrorClasses,
	secondaryTitleStyles
} from './utilClasses';
import { PasswordToggle } from './PasswordToggle';
import { BaseFontSizeContext } from '@adplabs/e-common/common/utils/BaseFontSizeContext';

const intlNamespace = 'mobile:AuthSignIn';
/* eslint-disable no-useless-computed-key */
export default class SignInView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			password: '',
			errorMsg: '',
			passwordVisible: false
		};

		this.handleChangeEmail = this.handleChangeEmail.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.passwordRef = createRef();
	}

	handleChangeEmail(email) {
		this.setState({ email, errorMsg: '' });
		this.props.onUserNameChange(email);
	}

	handleSubmit(e) {
		e.preventDefault();

		const { email, password } = this.state;
		const { handleSubmit, resetServerErrorMsg } = this.props;

		resetServerErrorMsg();

		if (!email.trim() || !password.trim()) {
			this.setState({
				errorMsg: eIntl.formatMessage(
					`${intlNamespace}.labelPleaseEnterUsernameAndPassword`
				),
			});

			return;
		}

		const errorMsg = emailPolicyValidation(email);

		if (errorMsg !== '') {
			this.setState({ errorMsg });
			return;
		}

		handleSubmit(password);
	}

	emailIsValid() {
		return emailPolicyValidation(this.state.email.trim()) === '';
	}

	render() {
		const { email, password, passwordVisible, errorMsg } = this.state;
		const {
			onSignUpClick,
			onForgotPwdClick,
			serverErrorMsg,
			onMoreOptionsClick,
			loading,
			providerID
		} = this.props;

		const baseFontSize = this.context.fontSize;
		const adjustForLargeBaseText = baseFontSize > 35;
		const hasProvider = !!providerID;
		const signupMarkup = (
			<div
				className={'mb-3'}
			>
				<span className={`text-neutral-800 ${adjustForLargeBaseText ? 'text-4xs' : ''}`}>
					{eIntl.formatMessage(`${intlNamespace}.labelHaveNoAccount`)}
				</span>{' '}
				<button
					type="button"
					onClick={onSignUpClick}
					className={classNames(linkClasses(adjustForLargeBaseText), 'p-0')}
					aria-label={eIntl.formatMessage(`${intlNamespace}.srCreateAccount`)}
				>
					{eIntl.formatMessage(`${intlNamespace}.labelSignUp`)}
				</button>
			</div>
		);

		return (
			<section className="sign-in">
				<h2 className={`${adjustForLargeBaseText ? 'text-3xs' : ''}`}>{eIntl.formatMessage(`${intlNamespace}.header`)}</h2>

				<h3 {...secondaryTitleStyles(adjustForLargeBaseText)}>
					{eIntl.formatMessage(`${intlNamespace}.subheader`)}
				</h3>

				<div className={formContainerClasses(baseFontSize >= 28)}>
					{!hasProvider && signupMarkup}

					<ValidationError
						error={errorMsg || serverErrorMsg}
						cssClass={[validationErrorClasses(adjustForLargeBaseText)]}
					/>

					<form
						onSubmit={this.handleSubmit}
					>
						<label htmlFor="emailAddress" className="sr-only">
							{eIntl.formatMessage(`${intlNamespace}.placeholderEmail`)}
						</label>

						<input
							id="emailAddress"
							type="email"
							inputMode="email"
							name="email"
							value={email}
							onChange={e => this.handleChangeEmail(e.target.value)}
							className={classNames(inputClasses(adjustForLargeBaseText))}
							placeholder={eIntl.formatMessage(
								`${intlNamespace}.placeholderEmail`
							)}
						/>

						<div
							className={`${!this.emailIsValid() ? passwordContainerClasses(false, hasProvider, adjustForLargeBaseText) : 'password-container mt-6'}`}
						>
							<label htmlFor="password" className="sr-only">
								{eIntl.formatMessage(`${intlNamespace}.placeholderPassword`)}
							</label>

							<input
								id="password"
								type={passwordVisible ? 'text' : 'password'}
								autoComplete="current-password"
								name="password"
								value={password}
								onChange={e => this.setState({ password: e.target.value, errorMsg: '' })}
								className={classNames({
									'text-2xs': baseFontSize >= 33  && baseFontSize < 35,
								}, inputClasses(adjustForLargeBaseText))}
								placeholder={eIntl.formatMessage(
									`${intlNamespace}.placeholderPassword`
								)}
								ref={this.passwordRef}
							/>

							<PasswordToggle
								show={passwordVisible}
								onToggle={() =>
									this.setState(
										{ passwordVisible: !this.state.passwordVisible },
										() => refocusInput(this.passwordRef.current)
									)
								}
								hidePasswordSrLabel={eIntl.formatMessage(
									`${intlNamespace}.srHidePassword`
								)}
								showPasswordSrLabel={eIntl.formatMessage(
									`${intlNamespace}.srShowPassword`
								)}
							/>
						</div>

						{this.emailIsValid() && (
							<button
								type="button"
								onClick={onForgotPwdClick}
								className={classNames(
									'forgot-or-change-password mt-3',
									linkClasses(adjustForLargeBaseText),
									'p-0',
									{
										'mb-2 mt-2': hasProvider,
									}
								)}
							>
								{eIntl.formatMessage(`${intlNamespace}.labelForgotPassword`)}
							</button>
						)}

						<ActionButton
							type="submit"
							primary
							className={classNames(submitContainerClasses(loading, hasProvider), {
								'mt-4 mb-4': !hasProvider && !adjustForLargeBaseText,
								'mt-2 mb-2': !hasProvider && adjustForLargeBaseText,
							})}
						>
							<span className="sr-only">
								{eIntl.formatMessage(`${intlNamespace}.labelSubmit`)}
							</span>
							<IconPaperplaneSolid />
						</ActionButton>
					</form>

					{hasProvider && signupMarkup}

					<div className={'mt-3'}>
						<span className={`text-neutral-800 ${adjustForLargeBaseText ? 'text-4xs' : ''}`}>
							{eIntl.formatMessage(`${intlNamespace}.labelOrReturnTo`)}
						</span>{' '}
						<button
							type="button"
							className={classNames(linkClasses(adjustForLargeBaseText), 'p-0')}
							onClick={onMoreOptionsClick}
							aria-label={eIntl.formatMessage(`${intlNamespace}.srLoginOptions`)}
						>
							{eIntl.formatMessage(`${intlNamespace}.labelLoginOptions`)}
						</button>
					</div>
				</div>
			</section>
		);
	}
}

SignInView.contextType = BaseFontSizeContext;

function refocusInput(element) {
	element.focus();

	const value = element.value;
	element.value = '';
	element.value = value;
}

SignInView.propTypes = {
	onSignUpClick: PropTypes.func,
	onMoreOptionsClick: PropTypes.func,
	onForgotPwdClick: PropTypes.func,
	resetServerErrorMsg: PropTypes.func,
	handleSubmit: PropTypes.func,
	onUserNameChange: PropTypes.func,
	loading: PropTypes.bool,
	serverErrorMsg: PropTypes.string,
	providerID: PropTypes.string
};

eIntl.addPart({
	name: intlNamespace,
	getMessages: locale => require(`../../i18n/mobile-${locale}`)
});
