import React from 'react';
import PropTypes from 'prop-types';
import appHistory from '../appHistory';
import Loader from '../components/Loader';
import session from '../UserSession';
import DeviceParams from '../utils/deviceParams';
import { ChatStandalone } from '@adplabs/e-chat-client';
import { connect } from 'react-redux';
import { eIntl } from '@adplabs/e-common/ui-intl';
import { getImpersonationMode } from '../utils/impersonation';
import { startConversation } from '../actions/conversation';
import * as SecureKeyStore from '../utils/cordovaSecureKeyStorePluginAPI';
import {
	// setHeaderTitle,
	setHeaderTitleToken,
	showLangaugeSelector } from '../actions/header';
import { updateDeviceInfo } from '../actions/user';
import { setStepUp } from '../actions/layout';
import * as Auth from '../utils/Auth';

import _log from '../log';
const log = _log('app:chat-stepUp-view');

const intlNamespace = 'mobile:StepUpView';

class StepUpView extends React.Component {
	constructor(props) {
		super(props);
		log('DEBUG', 'StepUpView:', this.props.location);
		this.handleConversationStart = this.handleConversationStart.bind(this);
		this.handleConversationFinish = this.handleConversationFinish.bind(this);
		this.pathToRedirect = '/chat';
		this.conversation = null;
		this.state = {
			showChat: true,
			loadedAlready: false
		};
	}

	componentDidMount() {
		if (this.conversation) {
			const { canonical } = this.conversation;
			const titlePath = 'header';
			this.props.dispatch(
				setHeaderTitleToken(`${intlNamespace}.${titlePath}`)
			);
		}
		// Action to refresh the UI for the mobile navigation
		this.props.dispatch(setStepUp(true));
	}

	async handleConversationStart(event) {
		log('DEBUG', 'StepUpView:: handleConversationStart', { event });
		startConversation({
			canonical: 'user.verify',
			eventParams: {
				clientID: this.props.user.client.clientID
			}
		});
		if(event.conversation.canonical === 'user.verify') {
			log('DEBUG', 'StepUpView:: handleConversationStart - showLangaugeSelector');
			this.props.dispatch(showLangaugeSelector(true));
		}
		this.setState({ loadedAlready: true });
	}

	async handleConversationFinish(event) {
		log('DEBUG', 'StepUpView:: handleConversationFinish', { event });
		this.setState({ showChat: false });
		const { canonical, conversationStatus } = event.conversation;
		if(canonical === 'user.verify') {
			log('DEBUG', 'StepUpView:: handleConversationFinish - showLangaugeSelector');
			this.props.dispatch(showLangaugeSelector(false));
		}
		SecureKeyStore.removeKey('stepup').then(() => {
			if (
				(canonical === 'user.verify') &&
				conversationStatus === 'complete'
			) {
				// Action to refresh the UI for the mobile navigation
				this.props.dispatch(setStepUp(false));
				// Set the userDeviceID so that we dont trigger stepup again for this same device
				const user = session.getUserSync();
				if (user.personID !== null && !user.actingAsID) {
					DeviceParams.gatherDeviceParameters()
						.then(params => {
							if (params.deviceID) {
								log('DEBUG', 'DeviceParams: submit update.device', params);
								this.props.dispatch(updateDeviceInfo(params));
							}
							if (DeviceParams.getToken()) {
								return SubmitDeviceToken(DeviceParams.getToken());
							} else return null;
						})
						.then(data => {
							log('DEBUG', 'SubmitDeviceToken: ', data);
						})
						.catch(err => {
							log('ERROR', 'DeviceParameter:', err);
						});
				}
				return appHistory.push(this.pathToRedirect);
			} else {
				// return appHistory.push(`/error?msg=${'Registration error'}`);
				log('ERROR', 'StepUpView:: handleConversationFinish error', {
					event
				});
				return appHistory.push(
					`/error?msg=${eIntl.formatMessage(
						`${intlNamespace}.conversationIncomplete`
					)}`
				);
			}
		})
			.catch((err) => {
				log('ERROR', 'StepUpView:: removing key error', {
					err
				});
				return appHistory.push(
					`/error?msg=${eIntl.formatMessage(
						`${intlNamespace}.keystoreError`
					)}`
				);
			});
	}

	render() {
		const conversation = {
			canonical: 'user.verify',
			params: {
				email: this.props.user.signupEmailAddress,
				personID: this.props.user.personID
			}
		};
		this.conversation = conversation;
		return (
			<section
				className="chat-view-container"
				role="main"
				aria-label="StepUp View"
			>
				<div className="adp-e-mobile-content">
					{!this.state.showChat ? (
						<Loader />
					) : (
						<ChatStandalone
							active
							conversation={this.conversation}
							impersonation={getImpersonationMode(this.props.user)}
							key={this.props.user.userAccountID}
							onConversationFinish={this.handleConversationFinish}
							onConversationStart={this.handleConversationStart}
							onLogout={() => Auth.logOut()}
							singleConversationMode
						/>
					)}
				</div>
			</section>
		);
	}
}

StepUpView.propTypes = {
	dispatch: PropTypes.func,
	location: PropTypes.object,
	user: PropTypes.object
};

eIntl.addPart({
	name: intlNamespace,
	getMessages: locale => require(`../i18n/mobile-${locale}`)
});

const mapStateToProps = ({
	user
}) => ({
	user
});

export default connect(mapStateToProps)(StepUpView);

function initiateCompanySetupConv(socket, dispatch, conv) {
	if (socket.isConnected()) {
		dispatch(conv);
	} else {
		setTimeout(() => {
			initiateCompanySetupConv(socket, dispatch, conv);
		}, 300);
	}
}
