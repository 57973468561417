import * as moment from 'moment-timezone';
// VKS: Even though this import is not used, this must be here
// This is so localize loads other locales: otherwise, when asked
// to format dates and what not, localize will just use English formats/values
// This can be clearly seen while rendering Timeline's Now bar
// eslint-disable-next-line no-unused-vars
import * as momentLocales from 'moment/min/locales.min';
import cookies from 'browser-cookies';
import { eIntl } from '@adplabs/e-common/ui-intl';
import { isSBSDesktop } from './utils/appInfo';
import * as SecureKeyStore from './utils/cordovaSecureKeyStorePluginAPI';
import { languageSelectorFeature } from './features/langaugeSelectorFeature';

// This list came from DAs.
// TRAC-25914 - disable all other locales: default to English for now.
// See the discussion at https://chat.adp.ai/channel/e?msg=Favtv8HF5dmxkmnqb
// export const locales = [
// 	// 'af',
// 	// 'am',
// 	// 'ar',
// 	// 'az',
// 	// 'bg',
// 	// 'bn',
// 	// 'bs',
// 	// 'ca',
// 	// 'ceb',
// 	// 'co',
// 	// 'cs',
// 	// 'cy',
// 	// 'da',
// 	// 'de',
// 	// 'el',
// 	// 'eo',
// 	'es',
// 	'es-MX'
// 	// 'et',
// 	// 'eu',
// 	// 'fa',
// 	// 'fi',
// 	// 'fr',
// 	// 'fy',
// 	// 'ga',
// 	// 'gd',
// 	// 'gl',
// 	// 'gu',
// 	// 'ha',
// 	// 'haw',
// 	// 'hi',
// 	// 'hmn',
// 	// 'hr',
// 	// 'ht',
// 	// 'hu',
// 	// 'hy',
// 	// 'id',
// 	// 'ig',
// 	// 'is',
// 	// 'it',
// 	// 'iw',
// 	// 'ja',
// 	// 'jw',
// 	// 'ka',
// 	// 'kk',
// 	// 'km',
// 	// 'kn',
// 	// 'ko',
// 	// 'ku',
// 	// 'lb',
// 	// 'lo',
// 	// 'lt',
// 	// 'lv',
// 	// 'mg',
// 	// 'mi',
// 	// 'mk',
// 	// 'ml',
// 	// 'mn',
// 	// 'mr',
// 	// 'ms',
// 	// 'mt',
// 	// 'ne',
// 	// 'nl',
// 	// 'no',
// 	// 'ny',
// 	// 'pa',
// 	// 'pl',
// 	// 'ps',
// 	// 'pt',
// 	// 'pt-BR',
// 	// 'ro',
// 	// 'ru',
// 	// 'sd',
// 	// 'si',
// 	// 'sk',
// 	// 'sl',
// 	// 'sm',
// 	// 'sn',
// 	// 'so',
// 	// 'sq',
// 	// 'sr',
// 	// 'st',
// 	// 'sv',
// 	// 'sw',
// 	// 'ta',
// 	// 'te',
// 	// 'tg',
// 	// 'th',
// 	// 'tl',
// 	// 'tr',
// 	// 'uk',
// 	// 'ur',
// 	// 'uz',
// 	// 'vi',
// 	// 'xh',
// 	// 'yi',
// 	// 'yo',
// 	// 'zh',
// 	// 'zh-TW',
// 	// 'zu'
// ];

export const DEFAULT_LOCALE = 'en';
const locales = ['es'];
// TODO: call GQL to get SystemSupportedLanguagesExpressedInLocaleCode
export const supportedLanguages = languageSelectorFeature.isEnabled() ? [
	{
		value: 'en',
		label: 'English',
	},
	{
		value: 'es',
		label: 'Español',
	},
] : [
	{
		value: 'en',
		label: 'English',
	}
];

const getNavigatorLanguage = () => {
	const language =
		(navigator.languages && navigator.languages[0]) ||
		navigator.language ||
		navigator.userLanguage;
	return language;
};

const getLocale = async () => {
	const customLocale = cookies.get('e-accept-language');
	if (customLocale) {
		return filterLocaleCode(customLocale);
	}
	const storedCustomLocale = await SecureKeyStore.getKey('locale');
	if (storedCustomLocale !== null) {
		return filterLocaleCode(storedCustomLocale);
	}
	const navigatorLanguage = getNavigatorLanguage();
	const exactLocale = locales.find((locale) => navigatorLanguage === locale);

	if (exactLocale) {
		return exactLocale;
	}

	if (navigatorLanguage.indexOf('-') > -1) {
		const [partialNavigatorLanguage] = navigatorLanguage.split('-');
		const partialLocale = locales.find(
			(locale) => partialNavigatorLanguage === locale
		);

		if (partialLocale) {
			return partialLocale;
		}
	}

	return DEFAULT_LOCALE;
};

export const setLocale = (locale) => {
	cookies.set('e-accept-language', locale, {
		expires: 365, // one year
		domain: window.location.hostname.replace('e.', ''),
	});
	SecureKeyStore.setKey('locale', locale);
};

export const getLanguageIdentifier = () => {
	const locale = cookies.get('e-accept-language');
	return filterLocaleCode(locale) || DEFAULT_LOCALE;
};

export const initLocale = async (selectedLocale) => {
	const locale = selectedLocale || (await getLocale());
	if (!isSBSDesktop()) {
		setLocale(locale);
	}
	await localizationServiceAPI(locale);
	moment.locale(locale.toLowerCase());
};

function localizationServiceAPI(locale) {
	return new Promise((resolve, reject) => {
		eIntl.setSupportedLocales(locales);
		eIntl.set(locale);
		const countryCode = cookies.get('e-country-code');
		if (countryCode) {
			eIntl.setCountryCode(countryCode);
		}
		resolve();
	});
}

function filterLocaleCode(locale) {
	switch (locale) {
		case 'en-US':
			return 'en';
		case 'es-MX':
			return 'es';
		default:
			return locale;
	}
}
