import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { eIntl } from '@adplabs/e-common/ui-intl';
// eslint-disable-next-line import/no-cycle
import DesktopHeader from '../components/DesktopHeader';
// eslint-disable-next-line import/no-cycle
import { UserShape, LocationShape, ClientShape } from './CoreLayout';

const intlNamespace = 'mobile:Footer';

const urlTerms =
	'https://static.adp.ai/help.html?fileList=https://docs.google.com/document/u/1/d/e/2PACX-1vTtYqd_HLy5-Q55PeI7r54dl7AJz27hQrqVPXY78BpXIH0Huym9Tf8hH1krSezVu2qhJ8g-XxsB2t8J/pub?embedded=true';
const urlPrivacy =
	'https://static.adp.ai/help.html?fileList=https://docs.google.com/document/u/1/d/e/2PACX-1vS6f_gnKSscdd_WXjJ43OvzjJIGvN1FxphvYuaAgZ_EAuOYVsLDRVQ04aXsShNTzwH0nGFkfTph-Nfd/pub?embedded=true,https://docs.google.com/document/u/1/d/e/2PACX-1vQ70_2TI4gPXPfDGBkIyJSQPf5bKK62xUqu0lWy5kvTl6NChZ5lwbgQoKSkZleyU5VuM9i1R9pjQtww/pub?embedded=true';

function DesktopFooter() {
	return (
		<div className="adp-desktop-footer">
			<div className="adp-footer-bottom-line">
				<div className="adp-copyright">
					<div>
						{eIntl.formatMessage(`${intlNamespace}.copyright`, {
							year: new Date().getFullYear(),
						})}
					</div>
				</div>
				<div className="adp-footer-links">
					<a
						href={urlPrivacy}
						target="_blank"
						title="Privacy"
						className="adp-copyright-link"
						rel="noreferrer"
					>
						{eIntl.formatMessage(`${intlNamespace}.privacy`)}
					</a>
					<a
						href={urlTerms}
						target="_blank"
						title="Terms"
						className="adp-copyright-link"
						rel="noreferrer"
					>
						{eIntl.formatMessage(`${intlNamespace}.terms`)}
					</a>
					<span className="adp-copyright-link adp-e-system-status">
						{eIntl.formatMessage(`${intlNamespace}.systemStatus`)}
					</span>
				</div>
			</div>
		</div>
	);
}

function DesktopLayout({
	children,
	user,
	onNavigate,
	location,
	renderHelp,
	client,
	toggleClientSwitchpopup,
	disableClientSwitch,
	header,
	setLayoverRef,
}) {
	const includeFooter = location.pathname === '/login';
	const panelRef = useRef(null);

	useEffect(() => {
		if (panelRef) {
			setLayoverRef(panelRef);
		}
	}, [panelRef]);	// eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="adp-desktop-wrapper">
			<DesktopHeader
				user={user}
				onNavigate={onNavigate}
				location={location}
				renderHelp={renderHelp}
				client={client}
				toggleClientSwitchpopup={toggleClientSwitchpopup}
				disableClientSwitch={disableClientSwitch}
				title={() => header()}
			/>
			<div className="adp-desktop-layover" ref={panelRef} />
			{children}
			{includeFooter ? <DesktopFooter /> : null}
		</div>
	);
}

DesktopLayout.propTypes = {
	children: PropTypes.object,
	user: UserShape,
	onNavigate: PropTypes.func,
	location: LocationShape,
	renderHelp: PropTypes.func,
	client: ClientShape,
	toggleClientSwitchpopup: PropTypes.func,
	disableClientSwitch: PropTypes.bool,
	header: PropTypes.func,
	setLayoverRef: PropTypes.func,
};

eIntl.addPart({
	name: intlNamespace,
	getMessages: (locale) => require(`../i18n/mobile-${locale}`),
});

export default DesktopLayout;
