import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { eIntl } from '@adplabs/e-common/ui-intl';
import classNames from 'classnames';
import moment from 'moment-timezone';
import {
	setHeaderTitleToken,
	saveHeaderTitle,
	restoreHeaderTitle
} from '../actions/header';
import {
	hideLiveChat,
	liveChatStarted,
	liveChatEnded,
	loadLiveChatParams,
	shouldRenderLiveChatIFrame,
	liveChatEmailFormCalled
} from '../actions/liveChat';
import { FAQ } from '@adplabs/e-common/ui-components';
import { logRollAnalyticsEvent, RollAnalyticsEvent } from '../lib/rollAnalytics/analytics';

import _log from '../log';
const log = _log('app:components:rocket-chat-live-support');

const LIVE_CHAT_URL = `${window.location.origin}/newchat/support/liveChat`;
const LIVE_CHAT_ENGINE_CIMPLICITY = 'cimplicity';
const LIVE_CHAT_ENGINE_ROCKET = 'rocket';
const LIVE_CHAT_ENGINE_AMAZON_CONNECT = 'amazon_connect';
export const LIVE_CHAT_ENGINE_SEND_EMAIL = 'emailsender';

const LIVE_CHAT_ROUTING_MODE_AUTO = 'auto';
const LIVE_CHAT_ROUTING_MODE_MANUAL = 'manual';

const intlNamespace = 'mobile:RocketChatLiveSupport';

class RocketChatLiveSupport extends React.Component {
	constructor(props) {
		super(props);

		this.handleLiveChatEnded = this.handleLiveChatEnded.bind(this);
		this.handleLiveChatStarted = this.handleLiveChatStarted.bind(this);
		this.handleAgentAssigned = this.handleAgentAssigned.bind(this);

		this.handleCrossWindowMessage = this.handleCrossWindowMessage.bind(this);
		this.loadLiveChatParams = this.loadLiveChatParams.bind(this);
		this.submitAnalyticsEvent = this.submitAnalyticsEvent.bind(this);
		this.handleLiveChatError = this.handleLiveChatError.bind(this);

		this.iFrameRef = React.createRef();
		this.tooltipRef = React.createRef();
		this.tooltipArrowRef = React.createRef();
		this.iFrameInnerFrameRef = React.createRef();

		this.currentLiveChatEngine = '';
		this.lastAgentAssigned = null;
		this.lastRoomID = null;
		this.rocketChatRoutingMode = LIVE_CHAT_ROUTING_MODE_AUTO;
		this.intervalID = undefined;
	}

	componentDidUpdate(/* prevProps, prevState, snapshot */) {
		if (!this.props.authenticated) {
			return;
		}
		if (this.iFrameInnerFrameRef.current) {
			this.iFrameInnerFrameRef.current.addEventListener('liveChatError', this.handleLiveChatError);
		}

		if (
			!this.props.liveChatEngineType &&
			!this.props.loadingLiveChatParams
		) {
			this.loadLiveChatParams();
			return;
		}

		const isVisible = !this.props.hidden;
		if (isVisible && !this.iFrameRef.current) {
			this.props.dispatch(shouldRenderLiveChatIFrame(true));
		}
	}

	componentDidMount() {
		window.addEventListener('message', this.handleCrossWindowMessage, false);
		this.loadLiveChatParams();
	}

	componentWillUnmount() {
		window.removeEventListener('message', this.handleCrossWindowMessage, false);
	}

	shouldComponentUpdate(nextProps) {
		if (this.iFrameRef.current) {
			if (this.props.hidden !== nextProps.hidden) {
				this.handleVisibilityChange(nextProps.hidden);
			}

			if (this.props.isFAQHidden !== nextProps.isFAQHidden) {
				this.handleVisibilityChange(nextProps.isFAQHidden);
				return true;
			}

			if (
				this.props.hidden &&
				!nextProps.hidden &&
				!nextProps.conversationActive
			) {
				return true;
			}

			return false;
		}

		return true;
	}

	handleLiveChatError() {
		this.props.dispatch(liveChatEmailFormCalled());
		this.forceUpdate();
	}

	loadLiveChatParams() {
		if (!this.props.authenticated) {
			return;
		}

		if (
			!this.props.liveChatEngineType &&
			!this.props.loadingLiveChatParams &&
			!this.props.errorLoadingLiveChatParams // will rety only when user clicking the help icon again
		) {
			this.props.dispatch(loadLiveChatParams({
				skipAgentStatusMonitoring: true
			}));
		}
	}

	adjustHeaderTitle() {
		this.props.dispatch(saveHeaderTitle());
		this.props.dispatch(
			setHeaderTitleToken(`${intlNamespace}.header`)
		);
	}

	handleCrossWindowMessage(message) {
		const { origin } = message;
		if (origin !== window.location.origin) {
			return;
		}

		const { data } = message;
		const { type } = data;

		switch (type) {
			case 'adp-live-chat-started':
				this.handleLiveChatStarted();
				break;
			case 'adp-live-chat-ended':
				this.handleLiveChatEnded();
				break;
			case 'adp-live-chat-agent-assigned':
				this.handleAgentAssigned(data.agent);
				break;
			case 'adp-live-chat-close-widget':
				this.props.dispatch(hideLiveChat());
				break;
			default:
				break;
		}
	}

	notifyLiveChatStarted(agent) {
		if (this.props.liveChatEngineType !== LIVE_CHAT_ENGINE_ROCKET) {
			return;
		}

		fetch(`${LIVE_CHAT_URL}/rocket/event/livechat.start`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			body: JSON.stringify({
				agent,
				routingMode: this.rocketChatRoutingMode
			})
		})
			.then(response => {
				if (response.ok) {
					log('DEBUG', 'notifyLiveChatStarted exiting OK: ', { agent });
					return response.json();
				} else {
					log('DEBUG', 'notifyLiveChatStarted exiting NOT OK: ', {
						agent
					});
					throw new Error('Error on livechat.start event');
				}
			})
			.then(({ roomID }) => {
				this.lastRoomID = roomID;
			})
			.catch(error => {
				log('ERROR', 'notifyLiveChatStarted exiting with Error: ', error);
			});
	}

	notifyLiveChatPreviewStarted() {
		if (this.props.liveChatEngineType !== LIVE_CHAT_ENGINE_ROCKET) {
			return;
		}

		fetch(`${LIVE_CHAT_URL}/rocket/event/liveChatPreview.start`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			},
			credentials: 'include'
		})
			.then(response => {
				if (response.ok) {
					log('DEBUG', 'notifyLiveChatPreviewStarted exiting OK');
				} else {
					log('DEBUG', 'notifyLiveChatPreviewStarted exiting NOT OK');
				}
			})
			.catch(error => {
				log(
					'ERROR',
					'notifyLiveChatPreviewStarted exiting with Error: ',
					error
				);
			});
	}

	notifyChatTransfered(agent, previousAgent) {
		if (this.props.liveChatEngineType !== LIVE_CHAT_ENGINE_ROCKET) {
			return;
		}

		fetch(`${LIVE_CHAT_URL}/rocket/event/livechat.transfer`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			body: JSON.stringify({
				previousAgent,
				agent,
				roomID: this.lastRoomID,
				routingMode: this.rocketChatRoutingMode
			})
		})
			.then(response => {
				if (response.ok) {
					log('DEBUG', 'notifyLiveChatTransfer exiting OK');
				} else {
					log('DEBUG', 'notifyLiveChatTransfer exiting NOT OK');
				}
			})
			.catch(error => {
				log('ERROR', 'notifyLiveChatTransfer exiting with Error: ', error);
			});
	}

	notifyLiveChatEnded() {
		if (this.props.liveChatEngineType !== LIVE_CHAT_ENGINE_ROCKET) {
			return;
		}

		if (!this.lastAgentAssigned || !this.lastRoomID) {
			// chat ended before agent entering the room
			log('ERROR', 'notifyLiveChatEnded not enought information', {
				agent: this.lastAgentAssigned,
				roomID: this.lastRoomID
			});
			return;
		}

		fetch(`${LIVE_CHAT_URL}/rocket/event/livechat.end`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			body: JSON.stringify({
				agent: this.lastAgentAssigned,
				roomID: this.lastRoomID
			})
		})
			.then(response => {
				if (response.ok) {
					log('DEBUG', 'notifyLiveChatEnded exiting OK');
				} else {
					log('DEBUG', 'notifyLiveChatEnded exiting NOT OK');
				}
			})
			.catch(error => {
				log('ERROR', 'notifyLiveChatEnded exiting with Error: ', error);
			});
	}

	handleLiveChatStarted() {
		// we don't do anythign really important on chat-started cb
		// because we need to wait until agent enters room on agent-assigned cb
		// AUTO routing => agent-assigned occurs before chat-started event
		// MANUAL routing => agent-assigned is alwasy after chat-started event
		if (
			this.props.liveChatEngineType === LIVE_CHAT_ENGINE_ROCKET &&
			this.lastAgentAssigned === null
		) {
			this.rocketChatRoutingMode = LIVE_CHAT_ROUTING_MODE_MANUAL;
			this.notifyLiveChatPreviewStarted();
		}

		this.props.dispatch(liveChatStarted());
	}

	handleLiveChatEnded() {
		this.props.dispatch(liveChatEnded());
		this.notifyLiveChatEnded();
		this.lastAgentAssigned = null;
		this.lastRoomID = null;

		if (this.liveChatEngineType === LIVE_CHAT_ENGINE_ROCKET) {
			this.rocketChatRoutingMode = LIVE_CHAT_ROUTING_MODE_AUTO;
		}
	}

	handleAgentAssigned(agent) {
		if (!agent) {
			// this happens when agents leaves
			// OR chat is transfered to another department
			return;
		}

		if (
			this.lastAgentAssigned &&
			this.lastAgentAssigned.username === agent.username
		) {
			// issue 604 on Rocket.Chat.Live, we need to ignore repeated calls
			// to onAssignAgent for the same agent
			// see https://github.com/RocketChat/Rocket.Chat.Livechat/issues/604
			// log('DEBUG', 'ignoring repeated call to handleAgentAssigned');
			return;
		}

		log('DEBUG', 'live agent assigned ', agent);

		if (this.lastAgentAssigned === null) {
			this.notifyLiveChatStarted(agent);
		} else {
			this.notifyChatTransfered(agent, this.lastAgentAssigned);
		}

		this.lastAgentAssigned = agent;
	}

	handleVisibilityChange(hidden) {
		const { isDesktopLayout, isFAQHidden, conversationActive, dispatch } =
			this.props;

		const nodeRef = isDesktopLayout
			? this.tooltipRef.current
			: this.iFrameRef.current;
		const arrowRef = this.tooltipArrowRef.current;

		if (hidden) {
			nodeRef.classList.remove('live-chat-visible');
			nodeRef.classList.add('live-chat-offscreen');
			dispatch(restoreHeaderTitle());
			if (arrowRef) {
				arrowRef.classList.add('live-chat-offscreen');
			}
		} else {
			// set header title only if live chat is open
			if (!isFAQHidden || conversationActive) {
				this.adjustHeaderTitle();
			}
			nodeRef.classList.remove('live-chat-offscreen');
			nodeRef.classList.add('live-chat-visible');
			if (arrowRef) {
				arrowRef.classList.remove('live-chat-offscreen');
			}
		}
	}

	getLiveChatEngineType(liveChatEngineType, isOffHours) {
		if (this.liveChatEngineType === LIVE_CHAT_ENGINE_ROCKET) {
			// rocket.chat is currently configured to auto-route mode
			// and will handle off hours by itself
			return LIVE_CHAT_ENGINE_ROCKET;
		}

		if (isOffHours) {
			return LIVE_CHAT_ENGINE_SEND_EMAIL;
		}

		return liveChatEngineType;
	}

	getIframeURL(isOffHours) {
		const cacheBust = new Date().getTime();
		const userToken = this.props.userToken;
		const { isDesktopLayout } = this.props;
		const timezone = moment.tz(moment.tz.guess()).zoneAbbr();
		const queryParams = [
			`userToken=${encodeURIComponent(userToken)}`,
			`tooltipLayout=${isDesktopLayout ? '1' : '0'}`,
			`timezone=${timezone}`,
			`cb=${cacheBust}`
		].join('&');
		const liveChatEngine = this.getLiveChatEngineType(
			this.props.liveChatEngineType,
			isOffHours
		);

		if (liveChatEngine !== LIVE_CHAT_ENGINE_SEND_EMAIL) {
			this.currentLiveChatEngine = liveChatEngine;
		}

		return `${LIVE_CHAT_URL}/${liveChatEngine}/${userToken}?${queryParams}`;
	}

	inShiftHours(serverTimezone, workingDayShifts) {
		const now = moment();
		const serverDate = moment().tz(serverTimezone);
		const todayStr = serverDate.format('YYYY-MM-DD');

		const getTwoDigitsTime = time => (time < 10 ? `0${time}`: time);

		return workingDayShifts.some((shift) => {
			const startHour = getTwoDigitsTime(shift.StartTime.Hours);
			const startMinute =  getTwoDigitsTime(shift.StartTime.Minutes);
			const shiftStartTime = moment.tz(`${todayStr} ${startHour}:${startMinute}`, serverTimezone);

			const endHour = getTwoDigitsTime(shift.EndTime.Hours);
			const endMinute = getTwoDigitsTime(shift.EndTime.Minutes);
			const shiftEndTime = moment.tz(`${todayStr} ${endHour}:${endMinute}`, serverTimezone);

			// Default validation for 24/7 is 00:00 for Start and End
			if (shiftStartTime.isSame(shiftEndTime)) {
				return true;
			}

			// there might be overlaping shifts registerd on the same day
			return (now.isAfter(shiftStartTime) && now.isBefore(shiftEndTime));
		});
	}

	checkAmazonConnectIsOffHours() {
		const { businessHoursDefinition } = this.props;
		const currentDay = moment().locale('en_US')
			.format('dddd')
			.toUpperCase();

		const inShiftResultsList = businessHoursDefinition.reduce((resultList, hoursOfOperationConfig) => {
			if (hoursOfOperationConfig === null) {
				return resultList;
			}

			const workingDayShifts = hoursOfOperationConfig.config.filter(workingDay => workingDay.Day === currentDay);
			const inShift = this.inShiftHours(hoursOfOperationConfig.timezone, workingDayShifts);
			return resultList.concat([inShift]);
		},[]);

		if (inShiftResultsList.length === 0) {
			// no definitions for current day of the week
			return false;
		}

		const allShiftsAreOffHours = !inShiftResultsList.some(validShift => validShift);

		return allShiftsAreOffHours;
	}

	checkIsOffHours(liveChatEngineType) {
		if (liveChatEngineType === LIVE_CHAT_ENGINE_AMAZON_CONNECT) {
			return this.checkAmazonConnectIsOffHours();
		}

		return false;
	}

	renderLiveChatIFrame() {
		const { shouldRenderLiveChatIFrame, loadingLiveChatParams } = this.props;

		if (loadingLiveChatParams) {
			return null;
		}

		if (!shouldRenderLiveChatIFrame) {
			return null;
		}

		this.adjustHeaderTitle();

		const isOffHours = this.checkIsOffHours(this.props.liveChatEngineType);
		const liveChatURL = this.getIframeURL(isOffHours);

		const liveChatEngine = this.getLiveChatEngineType(
			this.props.liveChatEngineType,
			isOffHours
		);

		const iFrameClassName =
			liveChatEngine === LIVE_CHAT_ENGINE_CIMPLICITY ||
			liveChatEngine === LIVE_CHAT_ENGINE_SEND_EMAIL ||
			liveChatEngine === LIVE_CHAT_ENGINE_AMAZON_CONNECT
				? 'iframe-cimplicity'
				: 'iframe-rocket-chat-live';

		return [
			<div
				key="live-chat-iframe-container"
				className="live-chat-iframe-container"
				ref={this.iFrameRef}
			>
				<iframe
					id="iframe-live-chat"
					title="Live Support"
					key="live_chat_iframe"
					name="live_chat_iframe"
					className={iFrameClassName}
					src={liveChatURL}
					ref={this.iFrameInnerFrameRef}
					onLoad={() => {
						this.watchForAmazonConnectIframe();
					}}
					frameBorder="0"
				/>
			</div>
		];
	}

	intervalID;

	watchForAmazonConnectIframe() {
		this.intervalID = setInterval(() => {
			const rollIFrame = document.querySelector('#iframe-live-chat');
			if (rollIFrame) {
				const awsConnectIFrame = rollIFrame.contentDocument.body.querySelector('#amazon-connect-chat-widget-iframe');
				const supportForm = rollIFrame.contentDocument.body.querySelector('.support-form-container ');
				if (awsConnectIFrame) {
					awsConnectIFrame.setAttribute('title', eIntl.formatMessage(`${intlNamespace}.awsConnectTitle`));
					this.clearAWCIframeInterval();
				} else if (supportForm) {
					this.clearAWCIframeInterval();
					this.darkenCloseButton(supportForm);
				}
			}
		}, 500);
	}

	clearAWCIframeInterval() {
		clearInterval(this.intervalID);
		this.intervalID = undefined;
	}

	darkenCloseButton(form) {
		form.querySelector('.support-form-buttons-container .close').setAttribute('style', 'background-color: #765dd5 !important;');
	}

	renderMobileLayout() {
		return (
			<div className="live-chat-container">
				{!this.props.isFAQHidden && (
					<div className="live-chat-iframe-container">{this.renderFAQ()}</div>
				)}
				{!this.props.hidden && this.renderLiveChatIFrame()}
			</div>
		);
	}

	submitAnalyticsEvent() {
		return (params) => {
			log('DEBUG', 'submitAnalyticsEvent', {
				event: RollAnalyticsEvent.FAQ_PAGE_VIEW,
				...params
			});
			logRollAnalyticsEvent(RollAnalyticsEvent.FAQ_PAGE_VIEW, params);
		};
	}

	renderFAQ() {
		return <FAQ onClickLiveChat={this.props.handleClickLiveChat} onClickQuestion={this.submitAnalyticsEvent()} />;
	}

	renderDesktopLayout() {
		const { hidden, isFAQHidden } = this.props;
		const isLivechatHidden = hidden;
		const cssClasses = classNames({
			'live-chat-tooltip-container': true,
			'live-chat-visible': !isFAQHidden || !isLivechatHidden,
			'live-chat-offscreen': hidden && isFAQHidden,
		});

		const arrowClassNames = classNames({
			'live-chat-tooltip-container-arrow': true,
			'live-chat-offscreen': hidden && isFAQHidden,
		});

		return (
			<div className={cssClasses} ref={this.tooltipRef}>
				<div className={arrowClassNames} ref={this.tooltipArrowRef} />
				<div className="live-chat-tooltip-container-border-node">
					{!isFAQHidden && this.renderFAQ()}
					{!hidden && this.renderLiveChatIFrame()}
				</div>
			</div>
		);
	}

	render() {
		if (!this.props.liveChatEngineType) {
			return '';
		}

		if (this.props.isDesktopLayout) {
			return this.renderDesktopLayout();
		}

		return this.renderMobileLayout();
	}
}

RocketChatLiveSupport.propTypes = {
	authenticated: PropTypes.bool.isRequired,
	businessHoursDefinition: PropTypes.any,
	conversationActive: PropTypes.bool,
	dispatch: PropTypes.func.isRequired,
	hidden: PropTypes.bool,
	isDesktopLayout: PropTypes.bool,
	liveChatEngineType: PropTypes.string.isRequired,
	shouldRenderLiveChatIFrame: PropTypes.bool,
	userToken: PropTypes.string.isRequired,
	isFAQHidden: PropTypes.bool,
	handleClickLiveChat: PropTypes.func.isRequired,
	loadingLiveChatParams: PropTypes.bool,
	errorLoadingLiveChatParams: PropTypes.bool
};

RocketChatLiveSupport.defaultProps = {
	conversationActive: false,
	hidden: true,
	isDesktopLayout: false,
	shouldRenderLiveChatIFrame: false,
	isFAQHidden: true,
	loadingLiveChatParams: false,
	errorLoadingLiveChatParams: false
};

const mapStateToProps = ({ liveChat, layout, user }) => ({
	authenticated: !!user.personID,
	businessHoursDefinition: liveChat.businessHoursDefinition,
	conversationActive: liveChat.conversationActive,
	hidden: liveChat.hiddenPortal,
	isDesktopLayout: layout.isDesktopLayout,
	liveChatEngineType: liveChat.liveChatEngineType,
	shouldRenderLiveChatIFrame: liveChat.shouldRenderLiveChatIFrame,
	userToken: liveChat.userToken,
	isFAQHidden: liveChat.isFAQHidden,
	loadingLiveChatParams: liveChat.loadingLiveChatParams,
	errorLoadingLiveChatParams: liveChat.errorLoadingLiveChatParams
});

eIntl.addPart({
	name: intlNamespace,
	getMessages: locale => require(`../i18n/mobile-${locale}`)
});

export default connect(mapStateToProps)(RocketChatLiveSupport);
