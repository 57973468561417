import {
	getTimelineEventsDetails,
	updateTimeline,
	updateTimelineTimeDetails
} from '@adplabs/e-common/ui-timeline';
import {
	getSocketConnection,
	setSocketConnection,
	setSocketDisconnected,
	setSocketConnectionAttempt
} from '../actions/socket';
import {
	setSearchResult,
	setSearchHistory
} from '@adplabs/e-common/ui-search';
import { setUserInfo } from '../actions/user';
import { timelineServicesURL } from '../utils/endpoints';
import deviceParams from '../utils/deviceParams';
import { logOut } from '../utils/Auth';	// eslint-disable-line import/no-cycle
import Socket from '../ClientSocketLib';
import session from '../UserSession';
import _log from '../log';
import { setLocale } from '../initLocale';
const log = _log('app:socket-middleware');

const socketConnectionMiddleware = store => next => action => {
	switch (action.type) {
		case 'GET_SOCKET_CONNECTION':
			Socket.getSocketHandle((err, socket) => {
				if (!err) {
					socket.on('TIMELINE_UPDATE', data => {
						const getTimelineFilter = people => {
							if (people) {
								const person = people.selectedPerson;
								const group = people.selectedGroup;
								return {
									people: []
										.concat((person && person.personID) || [])
										.concat(
											group && group.people
												? group.people.map(p => p.personID)
												: []
										)
								};
							}
							return {};
						};
						const timeline = store.getState().timeline;
						if (timeline && !timeline.firstLoad) {
							if (data.eventsToRemove && data.eventsToRemove.length > 0) {
								store.dispatch(
									updateTimeline(
										getTimelineFilter(store.getState().people),
										[],
										data.eventsToRemove,
										data.timestamp
									)
								);
							}
							if (data.punch) {
								store.dispatch(
									updateTimelineTimeDetails({ punch: data.punch })
								);
							}
							if (data.events && data.events.length > 0) {
								const eventIDs = data.events.map(event => event.id);
								store.dispatch(
									getTimelineEventsDetails(eventIDs, timelineServicesURL)
								);
							}
						}
					});

					socket.on('IMPERSONATION_SESSION_START', data => {
						log('INFO', 'IMPERSONATION_SESSION_START ws event');
						window.location.reload();
					});

					socket.on('SWITCH_CONTEXT', data => {
						log('INFO', 'SWITCH_CONTEXT ws event');
						window.location.reload();
					});

					socket.on('SET_LOCALE_CODE', localeCode => {
						log('INFO', 'SET_LOCALE_CODE ws event');
						setLocale(localeCode);
						window.setTimeout(() => {
							window.location.reload();
						}, 1000);
					});

					socket.on('IMPERSONATION_SESSION_END', data => {
						log('INFO', 'IMPERSONATION_SESSION_END ws event');
						const { user } = store.getState();
						if (user.actingAsID) {
							window.location.reload();
						}
					});

					socket.on('ON_SEARCH_RESP', data => {
						store.dispatch(setSearchResult(data.context, data));
					});

					socket.on('LOGOUT_USER', () => {
						logOut();
					});

					socket.on('GET_SEARCH_HISTORY_RESP', data => {
						store.dispatch(setSearchHistory(data.context, data));
					});

					socket.on('UPDATE_SEARCH_HISTORY', data => {
						store.dispatch(setSearchHistory(data.context, data));
					});

					socket.on('GET_USER_INFO', data => {
						Promise.resolve()
							.then(() => session.getUser())
							.then(() => {
								const user = session.getUserSync();
								store.dispatch(setUserInfo(user));
							})
							.catch(err => {
								log('ERROR', 'handleGetUserInfo:', err);
							});
					});

					socket.on('RESET_WS_CONNECTION', data => {
						Promise.resolve()
							.then(Socket.invalidateSession())
							.then(Socket.disconnect())
							.then(() => {
								return new Promise((resolve, reject) => {
									log('DEBUG', 'disconnected after RESET_WS_CONNECTION');
									setTimeout(() => {
										log(
											'DEBUG',
											'going to reconnect after RESET_WS_CONNECTION'
										);
										resolve();
									}, 10000);
								});
							})
							.then(() => {
								log('DEBUG', 'dispatch getSocketConnection()');
								store.dispatch(getSocketConnection());
							})
							.catch(err => {
								log('ERROR', 'handleResetWSConnection:', err);
							});
					});

					socket.on('connect', () => {
						store.dispatch(
							setSocketConnection({
								connected: true
							})
						);
						// update device parameters for registered user
						const user = session.getUserSync();
					});

					socket.on('disconnect', reason => {
						store.dispatch(
							setSocketDisconnected({
								reason,
								retrying: socket.io.opts.reconnection
							})
						);
					});

					socket.on('reconnect_attempt', attemptNumber => {
						store.dispatch(
							setSocketConnectionAttempt({
								attemptNumber,
								backoffTimeoutMS: socket.io.backoff.duration()
							})
						);
					});

					socket.on('reconnect', attemptNumber => {
						// get current actingAsID after WS reconnect
						// update user Info and
						// reload the app in case of any changes
						const { user } = store.getState();
						log('DEBUG', 'socketConnectionMiddleware: reconnect', user);
						const pastActingAsID = user.actingAsID;
						session.getUser().then(user => {
							log('DEBUG', 'socketConnectionMiddleware: getInfo', user);
							const { actingAsID = null } = user;
							if (pastActingAsID !== actingAsID) {
								location.reload();
							}
						})
							.catch(e => {
								log('ERROR', `socketConnectionMiddleware: getUser on reconnect error:${e.message}`);
							});
					});

					store.dispatch(setSocketConnection(socket));
				}
			});
			return next(action);
		case 'REMOVE_SOCKET_TOKEN':
			if (!action.fetching && !action.error) {
				Socket.disconnect();
			}
			return next(action);
		default:
			return next(action);
	}
};

export default socketConnectionMiddleware;
