import React from 'react';
import PropTypes from 'prop-types';
import { IconEye, IconHide } from '@adplabs/e-common/ui-svg';

export function PasswordToggle({
	show,
	onToggle,
	showPasswordSrLabel,
	hidePasswordSrLabel,
	className = '',
	size = 20
}) {
	const [label, IconElement] = show
		? [hidePasswordSrLabel, IconEye]
		: [showPasswordSrLabel, IconHide];

	return (
		<button
			type="button"
			className={`toggle-password-visibility ${className}`.trim()}
			onClick={onToggle}
			aria-disabled={!show}
		>
			<span className="sr-only">{label}</span>
			<IconElement size={size} color="#757575" />
		</button>
	);
}

PasswordToggle.propTypes = {
	show: PropTypes.bool,
	onToggle: PropTypes.func,
	showPasswordSrLabel: PropTypes.string,
	hidePasswordSrLabel: PropTypes.string,
	className: PropTypes.string,
	size: PropTypes.number
};
