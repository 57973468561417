import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	removeSelectedGroup,
	removeSelectedPerson,
} from '@adplabs/e-common/ui-people';
import debounce from 'lodash/debounce';
import { setScrollToNow } from '@adplabs/e-common/ui-timeline';
import InAppBrowserController from '@adplabs/e-common/common/utils/inAppBrowser';
import * as SecureKeyStore from '../utils/cordovaSecureKeyStorePluginAPI';
import { isIOS } from 'react-device-detect';
import { omit } from 'lodash';
// eslint-disable-next-line import/no-cycle
import DesktopLayout from './DesktopLayout';
import {
	matchPath,
	getLayoutClassNameFromRoute,
	isClientRoute,
	isPeopleProfileRoute,
	isPeopleRoute,
	isTimelineRoute,
	isChatRoute,
	isExternalContentURL,
} from '../utils/routeUtils';
import appHistory from '../appHistory';
import { logOut, updateUserInfo } from '../utils/Auth';
import { deleteLastVisited } from '../utils/trackUser';
import Header from '../components/Header';
import NavigationMenuPopup from '../components/NavigationMenuPopup';
import Navigation from '../components/Navigation';
import Modal from '../components/Modal';
import {
	endImpersonationSession,
	updatePersonLocale,
	setUserInfo,
} from '../actions/user';
import { hideLiveChat, toggleLiveChat, setFAQState, loadLiveChatParams } from '../actions/liveChat';
import {
	setHeaderTitleToken,
	saveHeaderTitle,
	restoreHeaderTitle,
} from '../actions/header';
import { setWindowSize, setWindowOrientation } from '../actions/layout';
import { setRoute } from '../actions/route';
import {
	clearRightPanelComponent,
	setRightPanelComponent,
} from '../actions/rightPanel';
import { SelectList } from '@adplabs/e-common/ui-input';

import RocketChatLiveSupport from '../components/RocketChatLiveSupport';
import RightPanel from '../components/RightPanel';
import classnames from 'classnames';
import IconHeadphones from '@adplabs/e-common/ui-svg/icon-headphones';
import IconHeadphonesSolid from '@adplabs/e-common/ui-svg/icon-headphones-solid';
import IconX from '@adplabs/e-common/ui-svg/icon-x';
import {
	toggleNavigationMenuPopup,
	hideNavigationMenuPopup,
} from '../actions/navigationMenuPopup';
import { supportedLanguages, initLocale } from '../initLocale';

import {
	SCREEN_ORIENTATION_LANDSCAPE,
	SCREEN_ORIENTATION_PORTRAIT,
} from '../constants';
import { eIntl } from '@adplabs/e-common/ui-intl';
import { setStatusBarBgToToken } from '../containers/Auth';
import { setTimelineSearch } from '../actions/timelineSocket';

const intlNamespace = 'mobile:MainHeader';
const DEFAULT_DEBOUNCE_MS = 300;

class CoreLayout extends React.Component {
	constructor(props) {
		super(props);

		this.handleWindowResize = debounce(
			this.handleWindowResize.bind(this),
			DEFAULT_DEBOUNCE_MS
		);
		this.handleClickSupportAction = this.handleClickSupportAction.bind(this);
		this.hadleClickEndSession = this.hadleClickEndSession.bind(this);
		this.handleClickSearchIcon = this.handleClickSearchIcon.bind(this);
		this.handleClickLogoutIcon = this.handleClickLogoutIcon.bind(this);
		this.handleNavigation = this.handleNavigation.bind(this);
		this.handleInAppBrowser = this.handleInAppBrowser.bind(this);
		this.handleClickCloseRightPanel =
			this.handleClickCloseRightPanel.bind(this);
		this.handleChangeLanguageSelectIcon =
			this.handleChangeLanguageSelectIcon.bind(this);

		// internal panels resize event handlers
		this.detailsPanelRef = React.createRef();
		this.layoverRef = React.createRef();

		this.handleInternalResizeSliderMouseDown =
			this.handleInternalResizeSliderMouseDown.bind(this);
		this.handleMouseMove = this.handleMouseMove.bind(this);
		this.handleMouseUp = this.handleMouseUp.bind(this);

		this.handleInternalResizeTouchStart =
			this.handleInternalResizeTouchStart.bind(this);
		this.handleTouchMove = this.handleTouchMove.bind(this);
		this.handleTouchEnd = this.handleTouchEnd.bind(this);

		this.handleScreenOrientationChange = debounce(
			this.handleScreenOrientationChange.bind(this),
			DEFAULT_DEBOUNCE_MS
		);

		this.handleKeyboardWillHide = this.handleKeyboardWillHide.bind(this);
		this.handleClickLiveChat = this.handleClickLiveChat.bind(this);

		this.state = {
			internalResizeActive: false,
			internalResizeDragX: 0,
			detailsPanelWidthPx: 0,
			showClientSwitchPopup: false,
			isClientSwitchloading: false
		};

		this.mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');
	}

	static lastPathName = '';

	static getDerivedStateFromProps(props) {
		const { pathname } = props.location;

		if (pathname !== CoreLayout.lastPathName) {
			CoreLayout.lastPathName = pathname;
			props.dispatch(setRoute(props.location.pathname));
		}

		return null;
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleWindowResize, false);
		window.addEventListener(
			'keyboardWillHide',
			this.handleKeyboardWillHide,
			false
		);

		this.startScreenOrientationDetector();

		if (this.detailsPanelRef.current) {
			this.setState({
				detailsPanelWidthPx: this.detailsPanelRef.current.offsetWidth,
			});
		}

		this.setStatusBarColor();

		this.setupStatusBarColorChange();
	}

	shouldComponentUpdate(nextProps, nextState) {
		const upNextProps = omit(nextProps, ['children']);
		const curProps = omit(this.props, ['children']);

		if (JSON.stringify(upNextProps) !== JSON.stringify(curProps)) {
			return true;
		}

		if (JSON.stringify(this.state) !== JSON.stringify(nextState)) {
			return true;
		}

		return false;
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize, false);
		window.removeEventListener(
			'keyboardWillHide',
			this.handleKeyboardWillHide,
			false
		);
		this.stopScreenOrientationDetector();

		this.cleanupStatusBarColorChange();
	}

	componentDidUpdate(prevProps) {
		const route = this.props.location.pathname || '';
		if (
			!this.props.isDesktopLayout &&
			!this.props.liveChat.hiddenPortal &&
			!this.props.liveChat.isFAQHidden &&
			matchPath(route, '/search')
		) {
			this.props.dispatch(hideLiveChat());
			this.props.dispatch(setFAQState(true));
		}
	}

	setupStatusBarColorChange() {
		if (
			this.mediaQueryList &&
			typeof this.mediaQueryList.addEventListener === 'function'
		) {
			this.mediaQueryList.addEventListener('change', this.setStatusBarColor);
		}
	}

	cleanupStatusBarColorChange() {
		if (
			this.mediaQueryList &&
			typeof this.mediaQueryList.removeEventListener === 'function'
		) {
			this.mediaQueryList.removeEventListener('change', this.setStatusBarColor);
		}
	}

	setStatusBarColor() {
		setStatusBarBgToToken('--alias-tile-background-secondary');
	}

	stopDetailsPanelResize() {
		this.setState({
			internalResizeActive: false,
			internalResizeDragX: 0,
		});
	}

	startDetailsPanelResize(event) {
		this.setState({
			internalResizeActive: true,
			internalResizeDragX: event.clientX,
		});
	}

	startScreenOrientationDetector() {
		this.screenOrientationMQL = window.matchMedia('(orientation: portrait)');

		if (typeof this.screenOrientationMQL.addListener === 'function') {
			// For Safari 14 and older, we need to user DEPRECATED addListener API
			this.screenOrientationMQL.addListener(this.handleScreenOrientationChange);
		} else {
			this.screenOrientationMQL.addEventListener(
				'change',
				this.handleScreenOrientationChange,
				false
			);
		}

		const { matches } = this.screenOrientationMQL;
		this.handleScreenOrientationChange({ matches });
	}

	stopScreenOrientationDetector() {
		if (typeof this.screenOrientationMQL.removeListener === 'function') {
			// For Safari 14 and older, we need to user DEPRECATED addListener API
			this.screenOrientationMQL.removeListener(
				this.handleScreenOrientationChange
			);
		} else {
			this.screenOrientationMQL.removeEventListener(
				'change',
				this.handleScreenOrientationChange,
				false
			);
		}

		this.screenOrientationMQL = null;
	}

	handleScreenOrientationChange(event) {
		const { matches: isPortrait } = event;
		const orientation = isPortrait
			? SCREEN_ORIENTATION_PORTRAIT
			: SCREEN_ORIENTATION_LANDSCAPE;

		if (orientation === this.props.orientation) {
			return;
		}

		const keyboardIsVisible = document.body.classList.contains(
			'adp-e-mobile-keyboard-visible'
		);
		if (keyboardIsVisible) {
			// avoid processing orientation change while the user is interacting with keyboard
			return;
		}

		this.props.dispatch(setWindowOrientation(orientation));
		this.setState({
			detailsPanelWidthPx: 0,
		});
	}

	handleKeyboardWillHide() {
		window.setTimeout(() => {
			const mql = window.matchMedia('(orientation: portrait)');
			this.handleScreenOrientationChange(mql);
		}, 1000);
	}

	handleInternalResizeSliderMouseDown(event) {
		this.startDetailsPanelResize(event);
	}

	handleInternalResizeTouchStart(event) {
		this.startDetailsPanelResize(event.changedTouches[0]);
	}

	handleMouseUp() {
		this.stopDetailsPanelResize();
	}

	handleTouchEnd() {
		this.stopDetailsPanelResize();
	}

	handleTouchMove(event) {
		this.handleMouseMove(event.changedTouches[0]);
	}

	handleMouseMove(event) {
		if (!this.state.internalResizeActive) {
			return;
		}

		if (!this.detailsPanelRef.current) {
			return;
		}

		const detailsPanelNode = this.detailsPanelRef.current;
		const startDragX = this.state.internalResizeDragX;
		const dragDelta = startDragX - event.clientX;

		const currentDetailsWidth = detailsPanelNode.offsetWidth;
		const nextDetailsWidth = currentDetailsWidth + dragDelta;

		this.setState({
			detailsPanelWidthPx: nextDetailsWidth,
			internalResizeDragX: event.clientX,
		});
	}

	handleInAppBrowser(url) {
		const {
			location: { pathname },
		} = this.props;

		if (this.props.isDesktopLayout) {
			if (isTimelineRoute(pathname) || isExternalContentURL(url)) {
				const inAppBrowser = InAppBrowserController.getInstance();
				inAppBrowser.clearOpenURLCallback();
				inAppBrowser.openURL(url);
				inAppBrowser.setOpenURLCallback(this.handleInAppBrowser);
			} else {
				this.props.dispatch(
					setRightPanelComponent({
						type: 'IN_APP_BROWSER',
						attributes: {
							userSelection: true,
							url,
						},
					})
				);
			}
		}
	}

	handleWindowResize() {
		this.props.dispatch(setWindowSize(window.innerHeight, window.innerWidth));
	}

	hadleClickEndSession() {
		this.props.dispatch(endImpersonationSession());
	}

	handleClickSearchIcon() {
		if (isTimelineRoute(this.props.location.pathname)) {
			this.props.dispatch(setTimelineSearch(true));
		}
		this.setState({ showClientSwitchPopup: false });
	}

	handleChangeLanguageSelectIcon(locale) {
		initLocale(locale)
			.then(() => {
				this.props.dispatch(updatePersonLocale(locale));
			})
			.catch((err) => {
				// eslint-disable-next-line no-console
				console.log(err);
			});
	}

	handleClickLogoutIcon() {
		logOut();
	}

	handleClickSupportAction() {
		const { conversationActive, isFAQHidden, hiddenPortal } =
			this.props.liveChat;
		const isLiveChatHidden = hiddenPortal;

		if (isLiveChatHidden && isFAQHidden) {
			// if there is an active conversation, open live chat
			if (conversationActive) {
				this.props.dispatch(toggleLiveChat());
			} else {
				// open FAQ & set the header title

				this.props.dispatch(loadLiveChatParams());
				this.props.dispatch(saveHeaderTitle());
				this.props.dispatch(setFAQState(!isFAQHidden));
				this.props.dispatch(setHeaderTitleToken(`${intlNamespace}.helpHeader`));
			}
		} else if (!isLiveChatHidden && isFAQHidden) {
			this.props.dispatch(hideLiveChat());
		} else if (isLiveChatHidden && !isFAQHidden) {
			// close FAQ & restore the header title

			this.props.dispatch(setFAQState(!isFAQHidden));
			this.props.dispatch(restoreHeaderTitle());
		}
	}

	handleClickCloseRightPanel() {
		const {
			location: { pathname },
			componentsByRoute,
		} = this.props;

		const isInChatRoute = isChatRoute(pathname);
		const currentRightSideComponent = componentsByRoute[pathname];
		const hasRightSideComponent = Boolean(currentRightSideComponent);

		if (
			isInChatRoute &&
			hasRightSideComponent &&
			currentRightSideComponent.type === 'IN_APP_BROWSER'
		) {
			this.props.dispatch(
				setRightPanelComponent({
					type: 'CHAT_CONVERSATION_TAGS',
					attributes: {},
				})
			);
			return;
		}

		this.props.dispatch(clearRightPanelComponent());
	}

	handleNavigation(origRoute, destRoute) {
		const { dispatch } = this.props;

		dispatch(hideLiveChat());
		dispatch(setFAQState(true));
		this.props.dispatch(setTimelineSearch(false));

		if (destRoute === 'logout') {
			logOut();
			return;
		}

		if (destRoute === 'userSettings') {
			this.props.dispatch(toggleNavigationMenuPopup());
			return;
		}

		if (
			matchPath(origRoute, '/timeline') &&
			matchPath(destRoute, '/timeline')
		) {
			dispatch(removeSelectedGroup());
			dispatch(removeSelectedPerson());
			dispatch(setScrollToNow(true));
		}

		if (
			matchPath(origRoute, '/people-group') &&
			matchPath(destRoute, '/people')
		) {
			dispatch(removeSelectedGroup());
			dispatch(removeSelectedPerson());
		}

		if (
			matchPath(origRoute, '/people-profile') &&
			matchPath(destRoute, '/people')
		) {
			dispatch(removeSelectedPerson());
		}

		appHistory.push(destRoute);
	}

	handleClickLiveChat() {
		this.props.dispatch(setFAQState(true));
		this.props.dispatch(toggleLiveChat());
	}

	handleMouseLeave = (e) => {
		// To avoid triggering mouseLeave on parent when mouseEnter on child.
		if (this.layoverRef && e.relatedTarget === this.layoverRef.current) {
			this.stopDetailsPanelResize();
		}
	};

	setOpenURLCallback() {
		if (this.props.isDesktopLayout) {
			InAppBrowserController.getInstance().setOpenURLCallback(
				this.handleInAppBrowser
			);
		} else {
			InAppBrowserController.getInstance().clearOpenURLCallback();
		}
	}

	shouldHideRightPanel() {
		const isInClientRoute = isClientRoute(this.props.location.pathname);
		const isInPeopleRoute =
			isPeopleProfileRoute(this.props.location.pathname) ||
			isPeopleRoute(this.props.location.pathname);
		const hasComponentInRightPanel = Boolean(
			this.props.componentsByRoute[this.props.location.pathname]
		);

		return (
			(isInClientRoute && !hasComponentInRightPanel) ||
			(isInPeopleRoute &&
				this.props.people.groups.length === 0 &&
				this.props.people.isPersonProfileOpen !== false)
		);
	}

	renderHeader() {
		const route = this.props.location.pathname || '';

		if (matchPath(route, '/search')) {
			return null;
		} else if (matchPath(route, '/qr-code-reader')) {
			this.props.dispatch(setHeaderTitleToken(`${intlNamespace}.scanTile`));
		}

		const hideSearchIcon =
			this.props.searchSettings.hidden ||
			(!this.props.liveChat.hiddenPortal && !this.props.isDesktopLayout);

		const chatVisible = matchPath(route, '/chat');
		const logoutVisible = matchPath(route, '/register') || matchPath(route, '/stepup');

		// show Globe icon (language) in Chat and Registration only
		// for user.register and company.setup only, #32110
		const languageSelectVisible =
			this.props.header.showLanguageSelector &&
			supportedLanguages.length > 1 &&
			(this.props.header.titleToken === 'mobile:ChatView.header' ||
				this.props.header.titleToken ===
					'mobile:RegistrationView.headerRegistration');

		const loadingRunnableConversations =
			chatVisible &&
			this.props.isDesktopLayout &&
			this.props.chat.runnableConversations.fetching;

		const showLoading =
			(chatVisible && !this.props.chat.connected) ||
			loadingRunnableConversations ||
			!this.props.socketInfo.connected ||
			this.props.people.loadingTiles ||
			this.props.people.loading;

		const hideLiveSupport =
			this.props.liveChat.hiddenIcon ||
			(this.props.isDesktopLayout && !this.shouldHideRightPanel());

		return (
			<Header
				actingAsMode={this.props.user.actingAsMode}
				hideLogoutIcon={!logoutVisible}
				hideSearchIcon={hideSearchIcon}
				hideSupportIcon={hideLiveSupport}
				hideLanguageSelectIcon={!languageSelectVisible}
				impersonatedClientName={this.props.user.impersonatedClientName}
				isDesktopLayout={this.props.isDesktopLayout}
				supportIconSelected={this.props.liveChat.iconSelected}
				liveChatActive={this.props.liveChat.conversationActive}
				onClickEndSession={this.hadleClickEndSession}
				onClickSearchIcon={this.handleClickSearchIcon}
				onClickLogoutIcon={this.handleClickLogoutIcon}
				onClickSupportAction={this.handleClickSupportAction}
				onChangeLanguageSelectIcon={this.handleChangeLanguageSelectIcon}
				showLoading={showLoading}
				title={eIntl.formatMessage(this.props.header.titleToken)}
				client={
					this.props.user.clients && this.props.user.clients.length > 1
						? this.props.user.client
						: null
				}
				languages={supportedLanguages}
				defaultLanguage={this.props.user.localeCode}
				toggleClientSwitchpopup={this.toggleClientSwitchpopup}
				disableClientSwitch={this.props.chat.chatBusyIndicator}
			/>
		);
	}

	renderLiveSupportTool() {
		const { user, isDesktopLayout } = this.props;
		const { personId, userAccountID, localeCode } = user || {};

		const sufix = [
			isDesktopLayout ? 'desktop' : 'mobile',
			personId || userAccountID || '',
			localeCode || 'en',
		].join('_');

		return (
			<RocketChatLiveSupport
				key={`rocket_chat_live_support_${sufix}`}
				handleClickLiveChat={this.handleClickLiveChat}
			/>
		);
	}

	renderLiveSupportButton() {
		if (this.props.liveChat.hiddenIcon || this.props.user.actingAsMode) {
			return null;
		}

		const cssClasses = classnames(
			'header-icon header-icon-support-icon mobile',
			{
				'header-icon-live-chat-visible': this.props.liveChat.iconSelected,
				'header-icon-live-chat-active': this.props.liveChat.conversationActive,
			}
		);

		const supportButtonAriaStatus = this.props.liveChat.conversationActive
			? 'Support Available'
			: 'Support Currently Unavailable';

		const icon = this.props.liveChat.conversationActive ? (
			<IconHeadphonesSolid />
		) : (
			<IconHeadphones />
		);

		return (
			<button
				className={cssClasses}
				aria-label={supportButtonAriaStatus}
				onClick={this.handleClickSupportAction}
			>
				<span>{eIntl.formatMessage(`${intlNamespace}.helpButtonLabel`)}</span>

				{icon}
			</button>
		);
	}

	renderCloseRightPanelButton() {
		if (this.shouldHideRightPanel()) {
			return null;
		}

		const {
			location: { pathname },
			componentsByRoute,
		} = this.props;

		const isInClientRoute = isClientRoute(pathname);
		const currentRightSideComponent = componentsByRoute[pathname];
		const hasRightSideComponent = Boolean(currentRightSideComponent);

		const showButtonOnClientView = isInClientRoute && hasRightSideComponent;

		const showButtonOnChatView =
			hasRightSideComponent &&
			currentRightSideComponent.type === 'IN_APP_BROWSER';

		if (showButtonOnClientView || showButtonOnChatView) {
			return (
				<button
					className="header-icon search-icon"
					onClick={this.handleClickCloseRightPanel}
				>
					<IconX />
				</button>
			);
		}

		return null;
	}

	renderNavigation() {
		return (
			<Navigation
				key={this.props.user.personID}
				active={this.props.location.pathname}
				disabled={!this.props.user.personID || this.props.stepUp}
				location={this.props.location}
				isDesktopLayout={this.props.isDesktopLayout}
				onNavigate={this.handleNavigation}
				user={this.props.user}
			/>
		);
	}

	renderMobile() {
		const { location, user, liveChat, timeline } = this.props;

		const layoutClassName = getLayoutClassNameFromRoute(location.pathname);
		const isSearchActive =
			timeline.timelineSearch && isTimelineRoute(location.pathname);

		return (
			<div
				className={`adp-e-mobile-container ${layoutClassName}`}
				// eslint-disable-next-line react/no-unknown-property
				locale={user.localeCode}
			>
				{this.renderLiveSupportTool()}
				{!isSearchActive ? this.renderHeader() : null}
				<div
					className={
						isSearchActive
							? 'adp-e-mobile-view-container timeline'
							: 'adp-e-mobile-view-container'
					}
				>
					{liveChat.isFAQHidden && liveChat.hiddenPortal ? (
						this.props.children
					) : (
						<div className="adp-e-mobile-content" />
					)}
					{this.renderCompanySwitchSelect()}
				</div>
				{this.renderNavigation()}
			</div>
		);
	}

	companySwitchHandler = (value, selectedItem) => {
		if (value == null && selectedItem) {
			this.setState({ isClientSwitchloading: true });
			updateUserInfo({ clientId: selectedItem[0] })
				.then((data) => {
					this.props.dispatch(setUserInfo(data));
					this.toggleClientSwitchpopup();
					this.setState({ isClientSwitchloading: false });
					deleteLastVisited();
					window.location.reload();
				})
				.catch((err) => {
					// eslint-disable-next-line no-console
					console.log('Error', err);
				});
		}
	};

	toggleClientSwitchpopup = () => {
		if (this.props.chat.chatBusyIndicator) {
			return;
		}

		this.setState({ showClientSwitchPopup: !this.state.showClientSwitchPopup });
	};

	renderCompanySwitchSelect = () => {
		const { user, isDesktopLayout } = this.props;
		const { isClientSwitchloading, showClientSwitchPopup } = this.state;
		const items = user.clients.map((client) => ({
			value: client.clientID,
			label: client.clientName,
		}));
		const defaultValue = items.filter(
			(item) => item.value === user.client.clientID
		);

		const intl = 'mobile:SelectList';

		return (
			<Modal
				className={classnames([
					'slide-from-bottom',
					isDesktopLayout ? 'action-modal--desktop' : 'action-modal--mobile',
				])}
				displayActionPopup={showClientSwitchPopup}
				handleClickOutside={this.toggleClientSwitchpopup}
			>
				<div className="adp-e-chat-ui-input-components">
					<SelectList
						items={items}
						disabled={isClientSwitchloading}
						required={true}
						onClick={this.companySwitchHandler}
						messages={{
							singleSelect: eIntl.formatMessage(`${intl}.SINGLE_SELECT_HEADER`),
						}}
						inline={true}
						selectedItems={defaultValue}
					/>
				</div>
			</Modal>
		);
	};

	renderDesktopLayout() {
		const layoutClassName = getLayoutClassNameFromRoute(
			this.props.location.pathname
		);

		const displayStyle = this.shouldHideRightPanel()
			? { display: 'none' }
			: null;

		const detailsPanelWidth =
			this.state.detailsPanelWidthPx > 0
				? { width: `${this.state.detailsPanelWidthPx}px` }
				: {};

		const detailsPanelStyle = Object.assign(
			{},
			displayStyle,
			detailsPanelWidth
		);

		const rootPanelClassNames = classnames({
			'adp-e-mobile-expanded-container': true,
			'mobile-ios': isIOS,
		});

		const renderHelp = () => {
			return (
				<div className="adp-e-mobile-header adp-header-help-menu">
					{/* {this.renderCloseRightPanelButton()} */}
					{this.renderLiveSupportButton()}
				</div>
			);
		};
		return (
			<DesktopLayout
				location={this.props.location}
				header={() => this.renderHeader()}
				user={this.props.user}
				onNavigate={this.handleNavigation}
				client={
					this.props.user.clients && this.props.user.clients.length > 1
						? this.props.user.client
						: null
				}
				renderHelp={renderHelp}
				toggleClientSwitchpopup={this.toggleClientSwitchpopup}
				disableClientSwitch={this.props.chat.chatBusyIndicator}
				setLayoverRef={(ref) => (this.layoverRef = ref)}
			>
				<div className="adp-desktop-content-wrapper">
					<div
						role="link"
						tabIndex={0}
						onMouseMove={this.handleMouseMove}
						onTouchMove={this.handleTouchMove}
						onMouseUp={this.handleMouseUp}
						onTouchEnd={this.handleTouchEnd}
						className={rootPanelClassNames}
						aria-label="Resizable container element"
						onMouseLeave={this.handleMouseLeave}
					>
						<div className="adp-e-mobile-expanded-container-panel">
							{this.renderNavigation()}
						</div>

						<div
							id="middlePanel"
							className="adp-e-mobile-expanded-container-panel"
						>
							<div
								className={`adp-e-mobile-expanded-main-content-panel ${layoutClassName}`}
							>
								{this.props.children}
								{this.renderCompanySwitchSelect()}
							</div>
						</div>
						<button
							onMouseDown={this.handleInternalResizeSliderMouseDown}
							onTouchStart={this.handleInternalResizeTouchStart}
							className="adp-e-mobile-expanded-container-panel"
							style={displayStyle}
						>
							<span className="dots">&#xFE19;</span>
						</button>
						<div
							ref={this.detailsPanelRef}
							className="adp-e-mobile-expanded-container-panel"
							style={detailsPanelStyle}
						>
							<RightPanel
								actingAsMode={this.props.user.actingAsMode}
								hideSupportIcon={this.props.liveChat.hiddenIcon}
								liveChatActive={this.props.liveChat.conversationActive}
								onClickSupportAction={this.handleClickSupportAction}
								supportIconSelected={this.props.liveChat.iconSelected}
							/>
						</div>
						{this.renderLiveSupportTool()}
					</div>
				</div>
				<NavigationMenuPopup
					showClientSwitch={
						this.props.user.clients && this.props.user.clients.length > 1
					}
					hidden={this.props.navigationMenuPopup.hidden}
					onClickOutside={() => this.props.dispatch(hideNavigationMenuPopup())}
					onClickLogout={() => logOut()}
					toggleClientSwitchpopup={this.toggleClientSwitchpopup}
					disableClientSwitch={this.props.chat.chatBusyIndicator}
				/>
			</DesktopLayout>
		);
	}

	render() {
		if (!this.props.user) {
			return null;
		}

		this.setOpenURLCallback();

		if (this.props.isDesktopLayout) {
			return this.renderDesktopLayout();
		}
		return this.renderMobile();
	}
}

const HeaderShape = PropTypes.shape({
	title: PropTypes.string,
	titleToken: PropTypes.string,
	showLanguageSelector: PropTypes.bool,
});

const SearchSettingsShape = PropTypes.shape({
	hidden: PropTypes.bool,
	searchPath: PropTypes.string,
	query: PropTypes.string,
});

const LiveChatShape = PropTypes.shape({
	conversationActive: PropTypes.bool,
	hiddenIcon: PropTypes.bool,
	hiddenPortal: PropTypes.bool,
	iconSelected: PropTypes.bool,
	isFAQHidden: PropTypes.bool,
});

const RunnableConversationsShape = PropTypes.shape({
	fetching: PropTypes.bool,
});

const ChatShape = PropTypes.shape({
	connected: PropTypes.bool,
	runnableConversations: RunnableConversationsShape,
	chatBusyIndicator: PropTypes.bool,
});

export const ClientShape = PropTypes.shape({
	clientID: PropTypes.string,
	clientName: PropTypes.string,
	isSmallBusiness: PropTypes.bool,
});

const clientsInfoSShape = PropTypes.arrayOf(
	PropTypes.shape({
		clientID: PropTypes.string,
		clientName: PropTypes.string,
	})
);

export const UserShape = PropTypes.shape({
	actingAsID: PropTypes.string,
	actingAsMode: PropTypes.string,
	applicationCodes: PropTypes.arrayOf(PropTypes.string),
	associateID: PropTypes.string,
	businessEmailAddress: PropTypes.string,
	client: ClientShape,
	countryCode: PropTypes.string,
	formattedName: PropTypes.string,
	impersonatedClientName: PropTypes.string,
	impersonatedFormattedName: PropTypes.string,
	impersonatedPersonID: PropTypes.string,
	impersonatedUserAccountID: PropTypes.string,
	liveSupportAuthorization: PropTypes.bool,
	personID: PropTypes.string,
	profileType: PropTypes.string,
	roleCodes: PropTypes.arrayOf(PropTypes.string),
	signupEmailAddress: PropTypes.string,
	timeZone: PropTypes.string,
	userAccountID: PropTypes.string,
	localeCode: PropTypes.string,
	clients: clientsInfoSShape,
});

const SocketInfoShape = PropTypes.shape({
	connected: PropTypes.bool,
	reason: PropTypes.string,
	retrying: PropTypes.bool,
	attemptNumber: PropTypes.number,
	backoffTimeoutMS: PropTypes.number,
});

const PeopleShape = PropTypes.shape({
	loadingTiles: PropTypes.bool,
	loading: PropTypes.bool,
	groups: PropTypes.array,
	isPersonProfileOpen: PropTypes.bool,
});

export const LocationShape = PropTypes.shape({
	pathname: PropTypes.string,
});

const NavigationMenuPopupShape = PropTypes.shape({
	hidden: PropTypes.bool,
});

CoreLayout.propTypes = {
	chat: ChatShape,
	children: PropTypes.element,
	dispatch: PropTypes.func,
	header: HeaderShape,
	liveChat: LiveChatShape,
	location: LocationShape,
	navigationMenuPopup: NavigationMenuPopupShape,
	orientation: PropTypes.string,
	people: PeopleShape,
	searchSettings: SearchSettingsShape,
	socketInfo: SocketInfoShape,
	user: UserShape,
	isDesktopLayout: PropTypes.bool,
	componentsByRoute: PropTypes.object,
	timeline: PropTypes.object,
	stepUp: PropTypes.bool
};

CoreLayout.defaultProps = {
	chat: {},
	children: [],
	header: {},
	liveChat: {},
	location: {},
	navigationMenuPopup: {},
	orientation: '',
	people: {},
	searchSettings: {},
	socketInfo: {},
	user: { client: {}},
	componentsByRoute: {},
	timeline: {}
};

const mapStateToProps = ({
	chat,
	header,
	layout,
	liveChat,
	navigationMenuPopup,
	people,
	searchSettings,
	socket,
	user,
	rightPanel,
	timeline,
	stepUp
}) => ({
	chat,
	header,
	liveChat,
	navigationMenuPopup,
	people,
	searchSettings,
	socketInfo: socket,
	user,
	isDesktopLayout: layout.isDesktopLayout,
	orientation: layout.orientation,
	componentsByRoute: rightPanel.componentsByRoute,
	timeline,
	stepUp: layout.stepUp
});

export default connect(mapStateToProps)(CoreLayout);

eIntl.addPart({
	name: intlNamespace,
	getMessages: (locale) => require(`../i18n/mobile-${locale}`),
});
