import _log from '../log';
import appHistory from '../appHistory';
import InAppBrowserController from '@adplabs/e-common/common/utils/inAppBrowser';
import { isAndroid } from '../utils/appInfo';
import { setUserReviewRequestDate } from '../actions/rateAndReview';
import { updateUserInfo } from '../utils/Auth';
import { setUserInfo } from '../actions/user';
import { timelineServicesURL } from '../utils/endpoints';
import { deleteLastVisited, setLastVisited } from '../utils/trackUser';
import { startConversation } from '../actions/conversation';

const log = _log('app:utils:PushNotificationRedirector');

class PushNotificationRouter {
	constructor() {
		this.appReady = false;
		this.store = null;
	}

	setStore(store) {
		this.store = store;
	}

	notifyAppReady() {
		this.appReady = true;
	}

	hasNotification() {
		const hasNotification = !!this.getStoredNotification();
		log('DEBUG', 'Has notification', hasNotification);
		return hasNotification;
	}

	storeNotification(notification) {
		if (!notification) {
			return;
		}
		log('INFO', 'Storing notification');
		try {
			window.sessionStorage.setItem('notification', JSON.stringify(notification));
		} catch (e) {
			log('ERROR', 'Error storing notification', e);
		}
	}

	removeStoredNotificationIfAny() {
		log('DEBUG', 'Removing stored notification if any');
		try {
			window.sessionStorage.removeItem('notification');
		} catch (e) {
			log('ERROR', 'Error removing stored notification', e);
		}
	}

	getStoredNotification() {
		let notification = null;
		try {
			notification = JSON.parse(window.sessionStorage.getItem('notification'));
		} catch (e) {
			log('ERROR', 'Error getting stored notification', e);
		}
		log('DEBUG', 'Get stored notification', notification);
		return notification;
	}

	dispatchUserReviewRequest() {
		try {
			log('DEBUG', 'received request to ask user review.');
			this.store.dispatch(setUserReviewRequestDate(new Date(), isAndroid()));
		} catch (error) {
			log('ERROR', 'error initiating user review request.', {
				error: error.message
			});
		}
	}

	handleClientSwitch(eventID, clientID) {
		updateUserInfo({ clientId: clientID })
			.then((userData) => {
				this.store.dispatch(setUserInfo(userData));

				if (eventID) {
					deleteLastVisited();
					setLastVisited(`/timeline/${eventID}`);
				}

				window.location.reload();
			})
			.catch((err) => {
				// eslint-disable-next-line no-console
				console.log('Error', err);
			});
	}

	route(notification = null) {
		const currentNotification = notification || this.getStoredNotification();

		log('DEBUG', 'handling notification: ', {
			currentNotification,
			appReady: this.appReady
		});

		if (this.appReady) {
			const { eventID, clientID, redirectTo, actionType, url, showRateAndReviewPopup } =
				currentNotification || {};

			if (showRateAndReviewPopup && this.store) {
				this.dispatchUserReviewRequest();
			}

			if (redirectTo && redirectTo === 'qr-code-reader') {
				appHistory.replace('/qr-code-reader');
				this.removeStoredNotificationIfAny();
				return;
			}

			if (actionType === 'EXTERNAL_LINK' && url) {
				appHistory.replace('/chat');
				window.setTimeout(() => {
					// wait for 2.5 secs to give time for the app to show chat?
					// need to improve to know exactly when loading is finished
					InAppBrowserController.getInstance().openURL(url);
				}, 2500);
				return;
			}

			if (clientID) {
				const { user } = this.store.getState();
				if (user.clientID !== clientID) {
					this.handleClientSwitch(eventID, clientID);
					return;
				}
			}

			if (eventID) {
				// Get event details because if it a Todo Event that can be done then just start the conversation in chat otherwise go to the timeline where the event is in view.
				fetchEvent(eventID).then((data) =>{
					const titelModifier = data.events[0].titleModifier;
					const doIt = data.events[0].primaryActions.includes('doIt');
					const id = data.events[0].id;
					const canonical = data.events[0].canonical;
					if(titelModifier && titelModifier === 'ToDo' && doIt){
						this.store.dispatch(startConversation({
							canonical,
							eventID: id,
							eventParams: {},
						}));
					}else {
						appHistory.replace(`/timeline/${eventID}`);
					}
				})
					.catch(err => log('ERROR', err));
				this.removeStoredNotificationIfAny();
				return;
			}

			this.removeStoredNotificationIfAny();
			return;
		}

		/* app is still in its loading cycle
		 * wait until ready notification */
		log('DEBUG', 'app not ready, notification saved to state.');
		this.storeNotification(currentNotification);
	}
}

function parseResponse(res) {
	if (res && res.ok) {
		try {
			return res.json();
		} catch (e) {
			log('ERROR', `returning parseReponse json error: ${e.message}`);
			throw e;
		}
	} else {
		throw new Error(`${res.status} - ${res.statusText}`);
	}
}

function fetchEvent(eventID) {
	return new Promise((resolve, reject) => {
		fetch(`${timelineServicesURL}/v1/events-details`, {
			method: 'POST',
			credentials: 'include',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				eventIDs: [eventID]
			})
		})
			.then(res => parseResponse(res))
			.then(data => resolve(data))
			.catch(err => {
				log('ERROR', 'fetchEvent', err);
				reject(err);
			});
	});
}

export default new PushNotificationRouter();
